// 用户管理

import request from "@/utils/request";

// 查询用户管理列表数据
export function searchUsers(data) {
  return request({
    url: "/adminUser/list",
    method: "post",
    data,
    Headers: {
      "Content-Type": "application/json",
    },
  });
}

//用户详情
export function getUserInfo() {
  return request({
    url: "/adminUser/latestUserinfo",
    method: "get",
    Headers: {
      "Content-Type": "application/json",
    },
  });
}

/**删除用户管理列表数据 */
export function delInvoiceData(userId) {
  return request({
    url: "/adminUser/delete?userId=" + userId,
    method: "post",
  });
}
/**更新用户管理列表数据 */
export function updateUser(data) {
  return request({
    url: "/adminUser/update",
    method: "post",
    data,
  });
}

// 获取订单数据
export function getOrderData(params) {
  return request({
    url: "/adminOrders/list",
    method: "get",
    params,
  });
}
