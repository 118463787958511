<template>
  <div id="product-main" ref="productRef"></div>
</template>

<script setup>
import * as echarts from "echarts";
import { nextTick, onMounted, watch, reactive, ref, unref } from "vue";
const props = defineProps({
  userData: {
    type: Array,
  },
});
watch(
  () => props.userData,
  () => setOption(),
  { deep: true }
);
const option = reactive({
  // 图表配置项
  backgroundColor: "#fff",
  //提示框
  tooltip: {
    trigger: "item", //触发显示方式 数据项触发
  },
  series: [
    {
      name: "",
      type: "pie", //图标类型
      radius: ["40%", "70%"], //半径
      center: ["50%", "50%"], //位置
      avoidLabelOverlap: false,
      label: {
        show: false, //文字说明 默认不显示
        position: "center", //文字中间显示
      },
      labelLine: {
        show: false,
      },
      data: [],
      color: ["#ff7a8c", "#73a0fa", "#24d2d3", "#ffc542", "#52c1f5"],
    },
  ],
});
const productRef = ref(null);
const myChart = ref(null);

function initCart() {
  option.series[0].data = props.userData ?? [];
  myChart.value = echarts.init(unref(productRef));
  myChart.value.setOption(option);

  window.addEventListener("resize", () => {
    myChart.value.resize();
  });
}
function setOption() {
  option.series[0].data = props.userData.map((v) => ({
    value: v.count,
    name: v.userGroup,
  }));
  myChart.value?.setOption(option);
}

onMounted(() => {
  nextTick(() => initCart());
});
</script>

<style>

#product-main {
  width: 2.75rem;
  height: 3.75rem;
  background-color: #fff;
}

</style>
