<template>
  <!-- 新增编辑发票开具信息 -->
  <div class="invoice-add-page">
    <div class="add-header-title">
      <span>开票信息</span>
      <span style="font-size: 0.15rem; margin-right: 0.1875rem"
        ><i style="color: red">*</i>为必填项</span
      >
    </div>
    <div class="info">
      <div class="make-info">
        <div class="title">
          <div class="box"></div>
          <span>开票信息</span>
        </div>
        <div class="make-ipt">
          <el-form ref="form1" :model="form" :rules="rules" label-width="100px">
            <el-row type="flex">
              <el-col :span="24">
                <el-form-item label="开具金额:" prop="price">
                  <el-input
                    placeholder="20000.00"
                    v-model="form.price"
                  ></el-input> </el-form-item
              ></el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="发票类别:" prop="invoiceFormat">
                  <el-select v-model="form.invoiceFormat" style="width: 100%">
                    <el-option label="增值税普通发票" value="1"></el-option>
                    <el-option label="增值税专用发票" value="2"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="发票形式:" prop="shape">
                  <el-radio-group v-model="form.invoicesType">
                    <el-radio-button :label="1">电子发票</el-radio-button>
                    <el-radio-button :label="2">纸质发票</el-radio-button>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="24">
                <el-form-item label="公司名称:" prop="invoiceHeading">
                  <el-input
                    placeholder="发票抬头"
                    v-model="form.invoiceHeading"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="税务登记号:" prop="taxesNumber">
                  <el-input
                    placeholder="30303060650606063060"
                    v-model="form.taxesNumber"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="开户行:" prop="bankName">
                  <el-input
                    placeholder="贵州银行"
                    v-model="form.bankName"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="银行账号:" prop="bankAccount">
                  <el-input
                    placeholder="165161611616161616"
                    v-model="form.bankAccount"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="开户行地址:" prop="bankAddress">
                  <el-input
                    placeholder="贵州省贵阳市云岩区新添大道南段"
                    v-model="form.bankAddress"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <div class="put-info">
        <div class="title">
          <div class="box"></div>
          <span>收件信息</span>
        </div>
        <div class="put-ipt">
          <el-form ref="form2" :model="form" label-width="100px" :rules="rules">
            <el-row v-if="form.invoicesType === 1">
              <el-col :span="24">
                <el-form-item
                  label="收件信箱:"
                  style="width: 70%; margin: auto"
                  prop="email"
                >
                  <el-input v-model="form.email"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <div v-if="form.invoicesType === 2">
              <el-row>
                <el-col :span="24">
                  <el-form-item
                    label="收件人:"
                    style="width: 70%; margin: auto"
                    prop="address.username"
                  >
                    <el-input v-model="form.address.username"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="24">
                  <el-form-item
                    label="收件地址:"
                    style="width: 70%; margin: 0.25rem auto"
                    prop="address.addressDetail"
                  >
                    <el-input v-model="form.address.addressDetail"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item
                    label="联系电话:"
                    style="width: 70%; margin: auto"
                    prop="address.mobile"
                  >
                    <el-input
                      v-model="form.address.mobile"
                      maxlength="11"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>

            <el-row type="flex" justify="center" style="margin-top: 0.375rem">
              <el-col :span="6">
                <el-button
                  type="primary"
                  @click="submit('form1', 'form2')"
                  style="width: 1.75rem"
                  >立即创建</el-button
                >
              </el-col>
              <el-col :span="6">
                <el-button @click="resetForm()" style="width: 1.75rem"
                  >重置</el-button
                >
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createInvoices } from "@/api/invoice";

export default {
  data() {
    return {
      radio1: "电子发票",
      form: {
        orderId: "",
        invoiceFormat: "",
        invoicesType: 1,
        price: 0,
        invoiceHeading: "",
        taxesNumber: "",
        bankName: "",
        bankAccount: "",
        bankAddress: "",
        email: "",
        address: {
          username: "",
          mobile: "",
          addressDetail: "",
        },
      },

      rules: {
        invoiceFormat: [
          {
            required: true,
            message: "请选择发票类型",
            trigger: "blur",
          },
        ],
        invoiceHeading: [
          {
            required: true,
            message: "请输入公司名称/发票抬头",
            trigger: "blur",
          },
        ],
        taxesNumber: [
          { required: true, message: "请输入税务登记号", trigger: "blur" },
        ],
        "address.username": [
          {
            required: true,
            message: "请输入内容",
            trigger: "blur",
          },
        ],
        "address.mobile": [
          {
            required: true,
            message: "请输入内容",
            trigger: "blur",
          },
        ],
        "address.addressDetail": [
          { required: true, message: "请输入内容", trigger: "blur" },
        ],
        email: [{ required: true, message: "请输入内容", trigger: "blur" }],
      },
    };
  },
  created() {
    if (this.$route.query.orderId) {
      this.form = { ...this.form, ...this.$route.query };
    }
  },
  methods: {
    submit() {// eslint-disable-next-line
      const form1 = new Promise((resolve, _) => {
        this.$refs["form1"].validate((valid) => {
          if (valid) resolve();
        });
      });
// eslint-disable-next-line
      const form2 = new Promise((resolve, _) => {
        this.$refs["form2"].validate((valid) => {
          if (valid) resolve();
        });
      });

      Promise.all([form1, form2]).then(() => {
        createInvoices(this.form).then((res) => {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.$router.back();
        });
      });
    },
    resetForm() {
      this.$nextTick(() => {
        this.$refs[("form1", "form2")].resetFields();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.invoice-add-page {
  width: 92%;
  height: 85%;
  min-height: 700px;
  margin: auto;
  border: 1px solid #fff;
  border-radius: 13px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  .add-header-title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 0.75rem;
    line-height: 0.75rem;
    font-size: 0.225rem;
    background-color: #f9f9f9;
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
    span {
      margin-left: 0.25rem;
    }
  }
  .info {
    width: 100%;
    height: 98%;
    .make-info {
      width: 95%;
      height: 60%;
      min-height: 500px;
      margin: auto;
      .title {
        height: 15%;
        display: flex;
        align-items: center;
        .box {
          width: 5px;
          height: 20px;
          background-color: #409eff;
          margin: 0 0.125rem;
        }
        span {
          font-size: 0.2rem;
          color: #666;
        }
      }
      .make-ipt {
        width: 30%;
        height: 80%;
        margin: auto;
      }
    }
    .put-info {
      width: 95%;
      height: 30%;
      min-height: 200px;
      margin: auto;
      .title {
        height: 15%;
        display: flex;
        display: flex;
        align-items: center;
        .box {
          width: 5px;
          height: 20px;
          background-color: #409eff;
          margin: 0 0.125rem;
        }
        span {
          font-size: 0.2rem;
          color: #666;
        }
      }
      .put-ipt {
        width: 44%;
        height: 80%;
        margin: auto;
      }
    }
  }
}
</style>
