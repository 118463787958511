<!-- 新增编辑修改用户信息 -->
<template>
  <div class="change-content">
    <div class="change-user"><span>新增编辑修改用户信息</span></div>

    <div class="basic-message">
      <div class="box"></div>
      <span>基本信息</span>
    </div>

    <div class="message-text">
      <div class="form">
        <el-form
          :model="ruleForm"
          status-icon
          :rules="rules"
          ref="ruleFormRef"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="手机号码:" prop="mobile">
            <el-input
              placeholder="请输入手机号码"
              v-model="ruleForm.mobile"
              maxlength="11"
            ></el-input>
          </el-form-item>
          <el-form-item label="初始密码:" prop="status">
            <el-switch
              v-model="ruleForm.status"
              inline-prompt
              active-text="启用"
              inactive-text="禁用"
              :active-value="1"
              :inactive-value="2"
            />
          </el-form-item>
          <el-form-item label="初始密码:" prop="password">
            <el-input
              type="password"
              v-model="ruleForm.password"
              autocomplete="new-password"
              readonly
              onfocus="this.removeAttribute('readonly');this.type='password'"
            ></el-input>
          </el-form-item>
          <el-form-item label="重复密码:" prop="duplicatePassword">
            <el-input v-model.number="ruleForm.duplicatePassword"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')">
              提交
            </el-button>
            <el-button @click="resetForm('ruleForm')">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <!-- <div class="user-img">
        <el-upload
          class="avatar-uploader"
          action="https://jsonplaceholder.typicode.com/posts/"
          :show-file-list="false"
          :on-change="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
        >
          <img v-if="imageUrl" :src="imageUrl" class="avatar" />
          <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
        </el-upload>
        <span>只支持.jpg格式</span>
      </div> -->
    </div>
  </div>
</template>

<script setup>
import { ElMessage } from "element-plus";
import { onMounted, ref } from "vue";
import { pick } from "lodash-es";
import { deepDiffBetweenObjects } from "@/utils";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { updateUser } from "@/api/user";

const store = useStore();
const keys = ["id", "mobile", "password", "duplicatePassword", "status"];
const ruleFormRef = ref(null);
const router = useRouter();
const route = useRoute();
const ruleForm = ref({
  id: "",
  mobile: "",
  password: "",
  duplicatePassword: "",
  status: 1,
});
const record = ref(null);

onMounted(() => {
  if (route.query?.id) {
    ruleForm.value = pick(route.query, keys);
    ruleForm.value.status = Number(ruleForm.value.status);
    record.value = { ...ruleForm.value };
  }
});

const validatePass = (_, value, callback) => {
  if (value) {
    if (ruleForm.value.duplicatePassword !== "") {
      if (!ruleFormRef.value) return;
      ruleFormRef.value.validateField("duplicatePassword", () => null);
    }
    callback();
  }
  callback();
};
const validatePass2 = (_, value, callback) => {
  if (value && value !== ruleForm.value.password) {
    callback(new Error("两次输入密码不一致!"));
  }
  callback();
};
// 验证规则
const rules = {
  realName: [{ required: true, message: "请输入用户名", trigger: "blur" }],
  mobile: [{ required: true, message: "请输入手机号码", trigger: "blur" }],
  password: [{ validator: validatePass, trigger: "blur" }],
  duplicatePassword: [{ validator: validatePass2, trigger: "blur" }],
};
// 表单提交
const submitForm = () => {
  ruleFormRef.value.validate(async (valid) => {
    if (valid) {
      console.log(ruleForm.value, record.value);
      let params = deepDiffBetweenObjects(ruleForm.value, record.value);
      params.id = record.value.id;
      const result = await updateUser(params);
      await store.dispatch("fetchUserInfo");
      ElMessage({
        message: result.msg,
        type: "success",
        duration: 2000,
      });
      router.back();
    }
  });
};
</script>

<style lang="less" scoped>
.change-content {
  width: 92%;
  height: 85%;
  min-height: 400px;
  margin: auto;
  border: 1px solid #fff;
  border-radius: 13px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  .change-user {
    width: 100%;
    height: 0.75rem;
    line-height: 0.75rem;
    font-size: 0.225rem;
    background-color: #f9f9f9;
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
    span {
      margin-left: 0.25rem;
    }
  }
  .basic-message {
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    .box {
      width: 5px;
      height: 25px;
      background-color: #409eff;
      margin-left: 1.5rem;
    }
    span {
      font-size: 0.225rem;
      margin-left: 0.1rem;
      color: #666666;
    }
  }

  .message-text {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    .form {
      margin-right: 30px;

      .el-form-item {
        margin-bottom: 0.375rem !important;
      }
    }
    .user-img {
      width: 30%;
      height: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      span {
        margin-top: 0.25rem;
        font-size: 0.175rem;
        color: #666666;
      }
    }
  }
}
.avatar-uploader,
.el-upload {
  width: 1.8rem;
  height: 1.6rem;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background-color: #fafafa;
}
.avatar-uploader:hover {
  border-color: #409eff;
}
.el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 0.35rem;
  color: #8c939d;
  width: 1.8rem;
  height: 1.6rem;
}
</style>
