import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/store";
import ElementPlus, { ElMessage } from "element-plus";
import "element-plus/dist/index.css";
import "./styles/index.css";
import "./utils/flexible";
import "windi.css";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import zhCn from "element-plus/dist/locale/zh-cn.mjs";
import "default-passive-events";
import { initAppConfigStore } from "./logic/initAppConfig";

const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
app.use(router);
app.use(store);
app.use(ElementPlus, {
  locale: zhCn,
});
// 初始化内部系统配置
initAppConfigStore();
app.mount("#app");

router.beforeEach((to, from, next) => {
  if (to.path == "/login") {
    next();
  } else {
    if (sessionStorage.getItem("TOKEN")) {
      // 权限判断
      const { path } = to;
      const menuList = store.getters.getMenuList;
      const parentPath = path.split("/").filter((v) => !!v);
      if (menuList.some((item) => item.name === parentPath[0])) {
        next();
      } else {
        ElMessage.closeAll();
        ElMessage({
          type: "error",
          message: "您没有权限访问该页面",
        });
        next(false);
      }
    } else {
      next({ path: "/login" });
    }
  }
});
