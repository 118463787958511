<template>
  <!-- <h1>发票详情</h1> -->
  <div class="invoice-info-page">
    <div class="info-header-title"><span>发票详情</span></div>

    <div class="info">
      <div class="consignee">
        <div class="title">
          <div class="box"></div>
          <span>收件人信息</span>
        </div>
        <div class="consignee-ipt">
          <div class="ipt-title">
            <div class="message"><span>收货人</span></div>
            <div class="message"><span>手机号码</span></div>
            <div class="message"><span>邮政编码</span></div>
            <div class="message"><span>收货地址</span></div>
          </div>
          <div class="ipt-info">
            <div class="info">
              <span>{{ username }}</span>
            </div>
            <div class="info">
              <span>{{ mobile }}</span>
            </div>
            <div class="info"><span></span></div>
            <div class="info">
              <span>{{ addressDetail }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="inv-info">
        <div class="title">
          <div class="box"></div>
          <span>发票信息</span>
        </div>
        <div class="inv-ipt">
          <div class="ipt-title">
            <div class="message"><span>发票类别</span></div>
            <div class="message"><span>发票抬头(企业名称)</span></div>
            <div class="message"><span>发票形式</span></div>
            <div class="message"><span>企业税号</span></div>
          </div>
          <div class="ipt-info">
            <div class="info">
              <span>{{
                invoicesType == 1 ? "普通增值税发票" : "专用增值税发票"
              }}</span>
            </div>
            <div class="info">
              <span>{{ invoiceHeading }}</span>
            </div>
            <div class="info">
              <span>{{ invoiceFormat == 1 ? "电子发票" : " 纸质发票" }}</span>
            </div>
            <div class="info">
              <span>{{ taxesNumber }}</span>
            </div>
          </div>
          <div class="ipt-title" style="border-top: 1px solid #dbdbdb">
            <div class="message"><span>产品名称</span></div>
            <div class="message"><span>发票全额(元)</span></div>
            <div class="message"><span>收票人手机</span></div>
            <div class="message"><span>收件人邮箱</span></div>
          </div>
          <div class="ipt-info">
            <div class="info">
              <span>{{ productName }}</span>
            </div>
            <div class="info">
              <span>{{ price }}</span>
            </div>
            <div class="info">
              <span>{{ mobile }}</span>
            </div>
            <div class="info">
              <span>{{ email }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getInvoiceInfoData } from "@/api/invoice.js";
export default {
  data() {
    return {
      username: "",
      mobile: "",
      taxesNumber: "",
      addressDetail: "",
      invoicesType: "",
      invoiceHeading: "",
      productName: "",
      price: "",
      email: "",
      invoicesId: "",
    };
  },
  created() {
    this.$route.query.id && this.getInvoiceInfoData(this.$route.query.id);
  },
  methods: {
    async getInvoiceInfoData() {
      const { data } = await getInvoiceInfoData(this.$route.query.id);
      this.username = data.username;
      this.mobile = data.mobile;
      this.taxesNumber = data.taxesNumber;
      this.addressDetail = data.addressDetail;
      this.invoicesType = data.invoicesType;
      this.invoiceHeading = data.invoiceHeading;
      this.invoiceFormat = data.invoiceFormat;
      this.productName = data.productName;
      this.price = data.price;
      this.email = data.email;
    },
  },
};
</script>

<style lang="less" scoped>
.invoice-info-page {
  width: 92%;
  height: 85%;
  margin: auto;
  border: 1px solid #fff;
  border-radius: 13px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  .info-header-title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 0.75rem;
    line-height: 0.75rem;
    font-size: 0.225rem;
    background-color: #f9f9f9;
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
    span {
      margin-left: 0.25rem;
    }
  }
  .info {
    width: 100%;
    height: 98%;
    .consignee {
      width: 95%;
      height: 30%;
      margin: auto;
      .title {
        height: 40%;
        display: flex;
        align-items: center;
        .box {
          width: 5px;
          height: 20px;
          background-color: #409eff;
          margin: 0 0.125rem;
        }
        span {
          font-size: 0.2rem;
          color: #666;
        }
      }
      .consignee-ipt {
        width: 97%;
        height: 40%;
        margin: auto;
        border: 1px solid #dbdbdb;

        .ipt-title {
          width: 100%;
          height: 50%;
          display: flex;
          justify-content: space-around;
          background-color: #f5f5f5;
          color: #666;
          .message {
            width: 100%;
            height: 100%;
            text-align: left;
            display: flex;
            align-items: center;
            border-right: 1px solid #dbdbdb;

            span {
              margin-left: 0.1875rem;
              font-size: 0.1625rem;
            }
          }
          .message:nth-child(4) {
            border-right: none;
          }
        }
        .ipt-info {
          width: 100%;
          height: 50%;
          display: flex;
          justify-content: space-around;
          .info {
            width: 100%;
            height: 100%;
            text-align: left;
            display: flex;
            align-items: center;
            border-right: 1px solid #dbdbdb;
            border-top: 1px solid #dbdbdb;
            border-bottom: none;
            span {
              margin-left: 13px;
              font-size: 0.1625rem;
            }
          }
          .info:nth-child(4) {
            border-right: none;
          }
        }
      }
    }
    .inv-info {
      width: 95%;
      height: 50%;
      margin: auto;
      .title {
        height: 15%;
        display: flex;
        display: flex;
        align-items: center;
        .box {
          width: 5px;
          height: 20px;
          background-color: #409eff;
          margin: 0 0.125rem;
        }
        span {
          font-size: 0.2rem;
          color: #666;
        }
      }
      .inv-ipt {
        width: 97%;
        height: 80%;
        margin: auto;
        border: 1px solid #dbdbdb;
        .ipt-title {
          width: 100%;
          height: 25%;
          display: flex;
          justify-content: space-around;
          background-color: #f5f5f5;
          color: #666;
          .message {
            width: 100%;
            height: 100%;
            text-align: left;
            display: flex;
            align-items: center;
            border-right: 1px solid #dbdbdb;
            span {
              margin-left: 0.1875rem;
              font-size: 0.1625rem;
            }
          }
          .message:nth-child(4) {
            border-right: none;
          }
        }
        .ipt-info {
          width: 100%;
          height: 25%;
          display: flex;
          justify-content: space-around;
          .info {
            width: 100%;
            height: 100%;
            text-align: left;
            display: flex;
            align-items: center;
            border-right: 1px solid #dbdbdb;
            border-top: 1px solid #dbdbdb;
            border-bottom: none;
            span {
              margin-left: 13px;
              font-size: 0.1625rem;
            }
          }
          .info:nth-child(4) {
            border-right: none;
          }
        }
      }
    }
  }
}
</style>
