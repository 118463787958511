

<template>
  <!-- 管理员详情 暂时不用 -->
  <div class="info-admin-page">
    <div class="manager">
      <div class="box">
        <div class="user-name">
          <p>
            <span class="name">ants</span> <span class="circle-box"></span>
            <span class="text">正常</span>
          </p>
          <p class="super">超级管理员</p>
        </div>

        <div class="back">
          <el-button
            type="primary"
            style="width: 1.25rem"
            @click="backAdminPage"
            >返回</el-button
          >
        </div>
      </div>
    </div>

    <div class="manager-table">
      <div class="user-title">
        <span>登录日志</span><span>操作记录</span><span>操作权限</span>
      </div>
      <div class="table">
        <el-table :data="tableData" border style="width: 100%" height="400">
          <el-table-column prop="loginTime" label="登录时间" min-width="12%">
          </el-table-column>
          <el-table-column prop="terminalType" label="终端类型"  min-width="10%">
          </el-table-column>
          <el-table-column prop="browser" label="浏览器/终端版本" min-width="12%"> </el-table-column>
          <el-table-column prop="system" label="操作系统"  min-width="10%">
          </el-table-column>
          <el-table-column prop="equipmentName" label="设备名称" min-width="10%">
          </el-table-column>
          <el-table-column prop="site" label="登录IP"  min-width="12%"> </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [
        {
          loginTime: "2016-05-02 14:53",
          terminalType: "WEB端",
          browser: "Firefox 68.0",
          system:'win10',
          equipmentName:'WindirPc',
          loginIp:'192.168.1.1',
          site:'广州省深圳市'
        },
        {
          loginTime: "2016-05-02 14:53",
          terminalType: "WEB端",
          browser: "Firefox 68.0",
          system:'win10',
          equipmentName:'WindirPc',
          loginIp:'192.168.1.1',
          site:'广州省深圳市'
        },
        {
          loginTime: "2016-05-02 14:53",
          terminalType: "WEB端",
          browser: "Firefox 68.0",
          system:'win10',
          equipmentName:'WindirPc',
          loginIp:'192.168.1.1',
          site:'广州省深圳市'
        },
        {
          loginTime: "2016-05-02 14:53",
          terminalType: "WEB端",
          browser: "Firefox 68.0",
          system:'win10',
          equipmentName:'WindirPc',
          loginIp:'192.168.1.1',
          site:'广州省深圳市'
        },
        {
          loginTime: "2016-05-02 14:53",
          terminalType: "WEB端",
          browser: "Firefox 68.0",
          system:'win10',
          equipmentName:'WindirPc',
          loginIp:'192.168.1.1',
          site:'广州省深圳市'
        },
        {
          loginTime: "2016-05-02 14:53",
          terminalType: "WEB端",
          browser: "Firefox 68.0",
          system:'win10',
          equipmentName:'WindirPc',
          loginIp:'192.168.1.1',
          site:'广州省深圳市'
        },
        {
          loginTime: "2016-05-02 14:53",
          terminalType: "WEB端",
          browser: "Firefox 68.0",
          system:'win10',
          equipmentName:'WindirPc',
          loginIp:'192.168.1.1',
          site:'广州省深圳市'
        },
        {
          loginTime: "2016-05-02 14:53",
          terminalType: "WEB端",
          browser: "Firefox 68.0",
          system:'win10',
          equipmentName:'WindirPc',
          loginIp:'192.168.1.1',
          site:'广州省深圳市'
        },
        {
          loginTime: "2016-05-02 14:53",
          terminalType: "WEB端",
          browser: "Firefox 68.0",
          system:'win10',
          equipmentName:'WindirPc',
          loginIp:'192.168.1.1',
          site:'广州省深圳市'
        },
        {
          loginTime: "2016-05-02 14:53",
          terminalType: "WEB端",
          browser: "Firefox 68.0",
          system:'win10',
          equipmentName:'WindirPc',
          loginIp:'192.168.1.1',
          site:'广州省深圳市'
        },
        {
          loginTime: "2016-05-02 14:53",
          terminalType: "WEB端",
          browser: "Firefox 68.0",
          system:'win10',
          equipmentName:'WindirPc',
          loginIp:'192.168.1.1',
          site:'广州省深圳市'
        },
        {
          loginTime: "2016-05-02 14:53",
          terminalType: "WEB端",
          browser: "Firefox 68.0",
          system:'win10',
          equipmentName:'WindirPc',
          loginIp:'192.168.1.1',
          site:'广州省深圳市'
        },

     
      ],
    };
  },
  methods: {
    backAdminPage() {
      this.$router.push("/adminPage");
    },
  },
};
</script>

<style lang="less" scoped>
.info-admin-page {
  width: 92%;
  height: 90%;
  margin: auto;
  border-radius: 13px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  .manager {
    width: 98%;
    height: 10%;
    background-color: #fff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin: auto;
    .box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 80%;
      height: 100%;
      margin: auto;
      .user-name {
        p {
          display: flex;
          align-items: center;

          .name {
            font-weight: 700;
            color: #666;
            font-size: 0.225rem;
          }
          .circle-box {
            display: block;
            width: 0.125rem;
            height: 0.125rem;
            background-color: #19be6b;
            border-radius: 50%;
            margin-left: 0.25rem;
          }
          .text {
            margin-left: 0.0625rem;
            color: #ccc;
          }
        }
        .super {
          font-size: 0.15rem;
          color: #999;
          margin-top: 0.125rem;
        }
      }
    }
  }

  .manager-table {
    width: 98%;
    height: 80%;
    margin: auto;
    border: 1px solid #fff;
    border-radius: 13px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    margin: auto;
    .user-title {
      width: 100%;
      height: 0.75rem;
      line-height: 0.75rem;
      font-size: 0.225rem;
      background-color: #f9f9f9;
      border-top-left-radius: 13px;
      border-top-right-radius: 13px;

      span {
        margin-left: 0.25rem;
        font-size: 0.2rem;
        color: #666;
        margin-left: 0.625rem;
      }
    }

    .table {
      width: 95%;
      height: 80%;
      margin: auto;
      margin-top: .375rem;
    }
  }
}
</style>