<template>
  <div class="login-content">
    <div class="content-box">
      <div class="left-bgimg">
        <div class="login-text">
          <p class="one">数智晟泰软件授权系统 V2.0</p>
          <p class="two">Digital ST Shengtai Software Authorization System</p>
        </div>
      </div>
      <div class="right-ipt">
        <div class="user-login-text">
          <div class="user-name">用户登录</div>
          <div class="english">USERLOGIN</div>
        </div>
        <el-form
          class="form-box"
          :model="loginForm"
          :rules="rules"
          ref="loginForm"
        >
          <el-form-item
            style="padding-top: 0.55rem"
            label="用户名："
            label-width="120"
            prop="adminName"
          >
            <el-input
              v-model="loginForm.adminName"
              placeholder="请输入用户名"
              style="width: 80%"
            ></el-input>
          </el-form-item>
          <el-form-item
            style="padding: 0.125rem 0"
            label="登录密码："
            label-width="120"
            prop="password"
          >
            <el-input
              v-model="loginForm.password"
              placeholder="请输入密码"
              style="width: 80%"
              type="password"
              autocomplete="new-password"
              readonly
              onfocus="this.removeAttribute('readonly');this.type='password'"
            ></el-input>
          </el-form-item>
          <el-form-item label-width="40">
            <el-input
              placeholder="请输入验证码"
              style="width: 50%; margin-right: 5%"
              v-model="loginForm.captcha"
            ></el-input>
            <img class="code" :src="captchaUrl" @click="getCaptcha" />
          </el-form-item>
          <div class="psw-text">
            <span class="rempsw">记住密码</span>
            <span class="forgettext">忘记密码？</span>
          </div>
          <div class="btn">
            <el-button type="primary" @click="onSubmit">登录</el-button>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { baseURL } from "@/config/env";

export default {
  data() {
    return {
      loading: false,
      loginForm: {
        adminName: "",
        password: "",
        // adminName: "admin",
        // password: "123456",
        captcha: "",
      },
      captchaUrl: "https://www.gzstjt.cn/v2/captcha",
      rules: {
        adminName: [
          { required: true, message: "用户名不能为空", trigger: "blur" },
        ],
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" },
        ],
        captcha: [
          { required: true, message: "图形验证码不能为空", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    if (process.env.NODE_ENV === "development") {
      this.loginForm.adminName = "";
      this.loginForm.password = "";
    }
    this.getCaptcha();
  },

  methods: {
    async getCaptcha() {
      if (process.env.NODE_ENV === "development") {
        this.captchaUrl =
        baseURL + "/captcha?" + Math.random();
      } else {
        this.captchaUrl =
        baseURL + "/captcha?" + Math.random();
      }
    },
    async onSubmit() {
      this.loading = true;
      const { loginForm } = this;
      const isSuccess = await this.$store.dispatch("doLogin", loginForm);
      isSuccess && this.$router.push("/home");
    },
  },
};
</script>

<style lang="less" scoped>
.login-content {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  .content-box {
    width: 40%;
    height: 50%;
    min-width: 400px;
    min-height: 300px;
    margin: auto;
    display: flex;

    .left-bgimg {
      width: 40%;
      height: 100%;
      background-image: url("@/assets/login/login.png");
      background-size: 100% 100%;
      .login-text {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        .one {
          font-size: 0.275rem;
          color: #fff;
          font-family: "思源-heavy";
        }
        .two {
          font-size: 0.15rem;
          color: rgba(255, 255, 255, 0.8);
          font-family: "思源-heavy";
        }
      }
    }

    .right-ipt {
      width: 60%;
      height: 100%;
      background-color: #fff;
      box-shadow: 3px 3px 5px #ccc;
      border-radius: 0 10px 10px 0;
      .user-login-text {
        display: flex;
        height: 20%;
        align-items: center;
        .user-name {
          color: #666666;
          font-size: 18px;
          margin: 0 5px 0 10px;
          font-family: "思源-heavy";
        }
        .english {
          color: rgba(0, 0, 0, 0.4);
          font-size: 16px;
          font-family: "思源-heavy";
        }
      }
      .form-box {
        height: 80%;
        margin: auto 0;
        .code {
          width: 30%;
          height: 100%;
          border: 1px solid #ccc;
          background-color: #fff;
        }

        .psw-text {
          width: 100%;
          height: 10%;
          display: flex;
          justify-content: space-between;
          font-size: 0.175rem;
          .rempsw {
            padding: 0 0.6875rem;
            cursor: pointer;
            color: rgba(204, 204, 204, 1);
          }
          .rempsw:hover {
            color: rgba(64, 158, 255, 1);
          }
          .forgettext {
            color: rgba(64, 158, 255, 1);
            padding-right: 0.75rem;
            cursor: pointer;
          }
        }
      }
      .btn {
        width: 100%;
        display: flex;
        justify-content: center;
        .el-button {
          width: 4.5rem;
          height: 0.625rem;
          font-weight: 700;
          font-size: 0.225rem;
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}
</style>
