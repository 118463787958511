<template>
  <!-- 新增管理员 -->
  <div class="add-admin-page">
    <div class="header-title">
      <span>新增管理员</span>
      <span style="font-size: 0.15rem; margin-right: 0.1875rem"
        ><i style="color: red">*</i>为必填项</span
      >
    </div>

    <div class="admin-message">
      <div class="box"></div>
      <span>基本信息</span>
    </div>

    <div class="add-form-item">
      <el-form
        :model="ruleForm"
        status-icon
        :rules="rules"
        ref="ruleFormRef"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="管理员账号:" prop="adminName">
          <el-input v-model="ruleForm.adminName" style="width: 50%"></el-input>
        </el-form-item>
        <el-form-item label="操作权限:">
          <el-checkbox-group v-model="ruleForm.roleId" @change="chagneRole">
            <el-checkbox
              :label="role.id"
              v-for="role in roles"
              :key="role.id"
              :disabled="
                ruleForm.roleId.includes(superCode) && role.id !== superCode
              "
              >{{ role.name }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="状态">
          <el-switch
            v-model="ruleForm.status"
            inline-prompt
            active-text="正常"
            inactive-text="关闭"
            :active-value="1"
            :inactive-value="2"
          />
        </el-form-item>

        <el-form-item label="手机号码:" prop="mobile">
          <el-input
            v-model.number="ruleForm.mobile"
            style="width: 50%"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input
            type="password"
            v-model="ruleForm.password"
            style="width: 50%"
            autocomplete="new-password"
            readonly
            onfocus="this.removeAttribute('readonly');this.type='password'"
          ></el-input>
        </el-form-item>
        <el-form-item label="重复密码:" prop="confirmPassword">
          <el-input
            type="password"
            v-model="ruleForm.confirmPassword"
            autocomplete="off"
            style="width: 50%"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="submitForm" style="width: 1.75rem">
            提交
          </el-button>
          <el-button @click="goBack" style="width: 1.75rem">返回</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script setup>
import { updateAdmin } from "@/api/admin";
import { getRoleList } from "@/api/role";
import { ElMessage } from "element-plus";
import { onMounted, ref } from "vue";
import { pick } from "lodash-es";
import { useRoute, useRouter } from "vue-router";
import { superCode } from "@/config/menu";
import { useStore } from "vuex";
const ruleFormRef = ref(null);
const store = useStore();
const router = useRouter();
const route = useRoute();
const roles = ref([]);

const ruleForm = ref({
  adminName: "",
  mobile: "",
  password: "",
  confirmPassword: "",
  status: "1",
  roleId: [],
  userId: 0,
});

onMounted(() => {
  getRoles();
  initForm();
});
// 角色改变
const chagneRole = (e) => {
  if (e.includes(superCode)) ruleForm.value.roleId = [superCode];
};

const initForm = () => {
  if (route.query?.id) {
    const record = route.query;
    const pickData = pick(record, ["adminName", "mobile", "roleId", "status"]);
    ruleForm.value = {
      ...ruleForm.value,
      ...pickData,
      userId: record.id,
      status: Number(pickData.status),
    };
  }
};
// 返回
const goBack = () => router.back();
// 获取角色列表
const getRoles = async () => {
  const result = await getRoleList();
  roles.value = result.data;
};

const validatePass = (_, value, callback) => {
  if (value) {
    if (ruleForm.value.confirmPassword !== "") {
      if (!ruleFormRef.value) return;
      ruleFormRef.value.validateField("confirmPassword", () => null);
    }
    callback();
  }
  callback();
};
const validatePass2 = (_, value, callback) => {
  if (value && value !== ruleForm.value.password) {
    callback(new Error("两次输入密码不一致!"));
  }
  callback();
};

const rules = {
  adminName: [{ required: true, message: "请输入管理员姓名", trigger: "blur" }],
  mobile: [{ required: true, message: "请输入手机号", trigger: "blur" }],
  password: [{ validator: validatePass, trigger: "blur" }],
  confirmPassword: [{ validator: validatePass2, trigger: "blur" }],
};

const submitForm = () => {
  ruleFormRef.value.validate(async (valid) => {
    if (valid) {
      const result = await updateAdmin(ruleForm.value);
      await store.dispatch("fetchUserInfo");
      ElMessage({
        message: result.msg,
        type: "success",
        duration: 2000,
      });
      router.back();
    }
  });
};
</script>

<style lang="less" scoped>
.add-admin-page {
  width: 92%;
  height: 85%;
  margin: auto;
  border: 1px solid #fff;
  border-radius: 13px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  .header-title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 0.75rem;
    line-height: 0.75rem;
    font-size: 0.225rem;
    background-color: #f9f9f9;
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
    span {
      margin-left: 0.25rem;
    }
  }

  .admin-message {
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    .box {
      width: 0.0625rem;
      height: 0.3125rem;
      background-color: #409eff;
      margin-left: 1.5rem;
    }
    span {
      font-size: 0.225rem;
      margin-left: 0.1rem;
      color: #666666;
    }
  }

  .add-form-item {
    width: 50%;
    height: 80%;
    margin: auto;
  }
}
</style>
