<!-- 产品管理 -->
<template>
  <div class="product-page">
    <div class="preview-data">
      <el-row :gutter="20" style="height: 100%" v-loading="loading">
        <el-col :span="6">
          <div class="pro-sum">
            <p>产品总数</p>
            <p>{{ productQuantity }}</p>
          </div></el-col
        >
        <el-col :span="6">
          <div class="pro-sum">
            <p>授权许可数量(用户数)</p>
            <p>{{ authorizationCount }}</p>
          </div></el-col
        >
        <el-col :span="6"
          ><div class="pro-sum">
            <p>产品总销售金额</p>
            <p>{{ salesAmount }}</p>
          </div></el-col
        >
        <el-col :span="6"></el-col>
      </el-row>
    </div>
    <div class="basic-info">
      <div class="top-btn">
        <el-row :gutter="40" style="width: 100%">
          <el-col :span="8">
            <el-radio-group v-model="query.status" @change="getProductList">
              <el-radio-button>全部项目</el-radio-button>
              <el-radio-button :label="1">上线中</el-radio-button>
              <el-radio-button :label="2"
                >已下线</el-radio-button
              ></el-radio-group
            ></el-col
          >
          <el-col :span="10">
            <el-input
              class="w-20"
              v-model="query.keywords"
              placeholder="请搜索关键词"
              @keyup.enter="getProductList"
              clearable
              @clear="getProductList"
            />
          </el-col>
          <el-col :span="2"></el-col>
          <el-col :span="2">
            <el-button type="primary" @click="addProduct"
              >增加产品</el-button
            ></el-col
          >
        </el-row>
      </div>
      <el-table :data="tableData" style="width: 95%; margin: auto">
        <el-table-column
          prop="name"
          label="基本信息"
          width="200"
          align="center"
        >
          <template #default="{ row }">
            <div
              style="
                display: flex;
                justify-content: space-around;
                align-items: center;
              "
            >
              <div style="width: 60%; font-size: 12px; white-space: pre-wrap">
                {{ row.name }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="是否上架"
          width="200"
          align="center"
          prop="onOffStatus"
        >
          <template #default="{ row }">
            <el-switch
              v-if="row.onOffStatus"
              @change="changeStatus($event, row)"
              :model-value="row.onOffStatus"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="2"
              :disabled="!row.onOffStatus"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column
          prop="price"
          label="定价(元)"
          width="200"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="softwareDescription"
          label="软件描述"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="sold"
          label="销售量(套)"
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="userGroup"
          label="用户群体"
          width="180"
          align="center"
        >
        <template #default="{ row }">
          <span v-show="row.userGroup==1"> 个人用户</span>
          <span v-show="row.userGroup==2"> 企事业单位</span>
          <span v-show="row.userGroup==3"> 政务部门</span>
          <span v-show="row.userGroup==4"> 注册机专用</span>
        </template>
        </el-table-column>
        <el-table-column
          prop="updatedTime"
          label="最后更新"
          width="250"
          align="center"
        >
        </el-table-column>
        <el-table-column label="操作" width="300" align="center" fixed="right">
          <template #default="{ row }">
            <el-button
              style="width: 0.8rem; font-size: 0.15rem"
              type="primary"
              plain
              @click="proInfo(row.id)"
              >详情</el-button
            >
            <el-button
              style="width: 0.8rem; font-size: 0.15rem"
              type="primary"
              plain
              @click="toEdit(row)"
              >编辑</el-button
            >
            <el-popconfirm
              title="确定删除吗?"
              @Confirm="confirmDel(row.id)"
              confirm-button-text="确定"
              cancel-button-text="取消"
            >
              <template #reference>
                <el-button
                  style="width: 0.8rem; font-size: 0.15rem"
                  plain
                  type="warning"
                  >删除</el-button
                >
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import {
  getProductData,
  getProductList,
  updateProduct,
  delProductData,
} from "@/api/product";
export default {
  data() {
    return {
      productQuantity: "",
      salesAmount: "",
      authorizationCount: "",
      value: true,
      tableData: [],
      loading: false,
      query: {
        keywords: "",
        status: "",
      },
    };
  },
  mounted() {
    this.getProductData();
    this.getProductList();
  },
  methods: {
    async getProductData() {
      const { data } = await getProductData();
      this.productQuantity = data.productQuantity;
      this.salesAmount = data.salesAmount;
      this.authorizationCount = data.authorizationCount;
    },
    //列表数据
    async getProductList() {
      const { query } = this;
      const { data } = await getProductList(query);
      this.tableData = data;
    },
    //详情
    proInfo(productId) {
      this.$router.push({
        path: "/productPage/productInfo",
        query: { id: productId },
      });
    },
    //状态更改
    async changeStatus(onOffStatus, { id }) {
      const result = await updateProduct({
        id,
        onOffStatus,
      });
      this.$message({
        message: result.msg,
        type: "success",
      });
      this.getProductList();
    },
    //新增
    addProduct() {
      this.$router.push("/productPage/addProduct");
    },
    // 编辑
    toEdit(query) {
      this.$router.push({
        path: "/productPage/editProduct",
        query,
      });
    },
    //删除数据
    confirmDel(productId) {
      delProductData(productId)
        .then((res) => {
          if (res.status == 200) {
            this.$message.success("删除成功");
            //删除成功 回调列表接口
            this.getProductList();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {
          this.$message.error("删除失败！");
        });
    },
  },
};
</script>

<style lang="less" scoped>
.product-page {
  width: 92%;
  height: 90%;
  margin: auto;
  border-radius: 13px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  .preview-data {
    width: 98%;
    height: 10%;
    min-height: 50px;
    background-color: #fff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin: auto;
    .pro-sum {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      p:first-child {
        color: #999;
        font-size: 0.175rem;
      }
      p:last-child {
        color: #666;
        font-size: 0.45rem;
      }
    }
  }

  .basic-info {
    width: 98%;
    height: 80%;
    min-width: 900px;
    min-height: 600px;
    margin: auto;
    border: 1px solid #fff;
    border-radius: 13px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    margin: auto;
    .top-btn {
      width: 100%;
      height: 10%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}
</style>
