<template>
  <div class="home-page">
    <div class="data-display">
      <div class="sum">
        <div class="sum-circle cricl-background-one">
          <img src="@/assets/home/user.png" alt="" />
        </div>
        <div class="sum-number">
          <span>{{ usersCount }}</span>
          <p>用户总数</p>
        </div>
      </div>
      <div class="sum">
        <div class="sum-circle cricl-background-two">
          <img src="@/assets/home/money.png" alt="" />
        </div>
        <div class="sum-number">
          <span>{{ orderCount }}</span>
          <p>成交金额汇总</p>
        </div>
      </div>
      <div class="sum">
        <div class="sum-circle cricl-background-three">
          <img src="@/assets/home/pay.png" alt="" />
        </div>
        <div class="sum-number">
          <span>{{ dailyTurnover }}</span>
          <p>今日成交金额</p>
        </div>
      </div>
      <div class="sum">
        <div class="sum-circle cricl-background-four">
          <img src="@/assets/home/list.png" alt="" />
        </div>
        <div class="sum-number">
          <span>{{ dayOrder }}</span>
          <p>今日成交单数</p>
        </div>
      </div>
      <div class="sum">
        <div class="sum-circle cricl-background-five">
          <img src="@/assets/home/amount.png" alt="" />
        </div>
        <div class="sum-number">
          <span>{{ totalAmount }}</span>
          <p>订单总数</p>
        </div>
      </div>
    </div>
    <div class="visual-container">
      <div class="region-info">
        <div class="day">
          <div class="day-text">会员地区排行</div>
          <el-radio-group
            class="radio-box"
            v-model="radio"
            @change="getDataForAxios"
          >
            <el-radio-button :label="1">本周</el-radio-button>
            <el-radio-button :label="2">本月</el-radio-button>
            <el-radio-button :label="3">今年</el-radio-button>
          </el-radio-group>
        </div>
        <!-- 中国地图 -->

        <chinamap :mapList="mapData" />

        <div class="map-table">
          <el-table :data="msgList" border style="width: 100%">
            <el-table-column type="index" label="序号" min-width="10%">
            </el-table-column>
            <el-table-column prop="city" label="地区" min-width="20%">
            </el-table-column>
            <el-table-column prop="count" label="用户数" min-width="20%">
            </el-table-column>
            <el-table-column prop="percentage" label="用户占比" min-width="20%">
            </el-table-column>
            <el-table-column label="占比" min-width="30%">
              <template #default="{ row }">
                <el-progress :percentage="row.percentage"></el-progress>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="statistical">
        <div class="circl-one">
          <div class="register-circl">
            <div class="title">注册软件版本类型统计</div>
          </div>
          <div class="circl-info">
            <!-- 环形图 -->
            <circl :annulusData="annulusData"></circl>
            <!--  注册软件版本类型统计-->

            <el-table
              :data="annulusData"
              border
              style="width: 100%"
              height="2.5rem"
            >
              <el-table-column prop="product_name" label="等级" width="160">
              </el-table-column>
              <el-table-column prop="count" label="数量" width="120">
              </el-table-column>
              <el-table-column prop="percentage" label="占比">
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="circl-two">
          <div class="product-circl">
            <div class="title">产品用户类型统计</div>
          </div>

          <div class="product-table">
            <!-- 环形图 -->
            <product :userData="userTypeData"></product>
            <!-- 产品用户类型统计 -->
            <el-table
              :data="userTypeData"
              border
              style="width: 100%"
              height="2.5rem"
            >
              <el-table-column prop="userGroup" label="类型" width="160">
              </el-table-column>
              <el-table-column prop="count" label="数量" width="120">
              </el-table-column>
              <el-table-column prop="percentage" label="占比">
              </el-table-column>
            </el-table>
          </div>
        </div>
        <!-- <div class="client-table">
          <div class="product-circl">
            <div class="title">客户待开发票</div>
          </div>
          <div class="table-info">
            <div class="table-title">
              <div class="title-text" style="height: 55px; line-height: 55px">
                客户名称
              </div>
              <div class="title-count" style="height: 55px; line-height: 55px">
                金额
              </div>
              <div class="title-duty" style="height: 55px; line-height: 55px">
                专票类型
              </div>
            </div>
            <div class="table-title">
              <div class="title-text">
                <span class="dot" style="background-color: #ffa94c"></span
                >贵州大学地质研究院
              </div>
              <div class="title-count">40000</div>
              <div class="title-duty">专票</div>
            </div>
            <div class="table-title">
              <div class="title-text">
                <span class="dot" style="background-color: #1891ff"></span
                >贵州铝镁设计研究院
              </div>
              <div class="title-count">10000</div>
              <div class="title-duty">普票</div>
            </div>
            <div class="table-title">
              <div class="title-text">
                <span class="dot" style="background-color: #52c1f5"></span
                >贵州晟泰集团
              </div>
              <div class="title-count">10000</div>
              <div class="title-duty">普票</div>
            </div>
            <div class="table-title">
              <div class="title-text">
                <span class="dot" style="background-color: #24d2d3"></span
                >杭州森林检测咨询有限公司
              </div>
              <div class="title-count">10000</div>
              <div class="title-duty">专票</div>
            </div>
            <div class="table-title">
              <div class="title-text">
                <span class="dot" style="background-color: #ff7a8c"></span
                >北京水土保持咨询有限公司
              </div>
              <div class="title-count">10000</div>
              <div class="title-duty">普票</div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import chinamap from "@/components/circl/home-chinamap.vue";
import circl from "@/components/circl/home-circl.vue";
import product from "@/components/circl/home-product.vue";
import { getHomeNumber } from "@/api/home";
export default {
  components: {
    chinamap,
    circl,
    product,
  },
  data() {
    return {
      radio: "",
      usersCount: 0,
      orderCount: 0,
      dailyTurnover: 0,
      dayOrder: 0,
      totalAmount: 0,
      tabPosition: "month",
      registerData: [], //注册软件版本类型统计
      userTypeData: [], //产品用户类型统计
      msgList: [],
      mapData: [],
      sumInfo: [],
      circlList: [],
      annulusData: [],
      productList: [],
    };
  },
  mounted() {
    //页面进入请求
    this.getDataForAxios();
  },
  methods: {
    getDataForAxios() {
      //参数提交  插值语法讲接口数据渲染到对应的标签
      getHomeNumber({
        time: this.radio,
      }).then((res) => {
        if (res.status === 200) {
          //金额数据
          this.sumInfo = res.data;
          this.usersCount = res.data.usersCount;
          this.orderCount = res.data.orderCount;
          this.dailyTurnover = res.data.dailyTurnover;
          this.dayOrder = res.data.dayOrder;
          this.totalAmount = res.data.totalAmount;

          // 注册软件版本类型统计
          this.annulusData = res.data.softwareStatistical;

          //产品用户类型统计
          this.userTypeData = res.data.usersGroupCount;

          // 地图表格数据
          this.msgList = res.data.city.map((item) => {
            return {
              city: item.city,
              count: item.count,
              percentage: Number(item.percentage.replace(/[^0-9]/g, "")),
            };
          });
          this.mapData = res.data.city.map((item) => {
            return {
              name: item.city?.replace("省", ""),
              value: item.count,
            };
          });
          this.circlList = res.data.softwareStatistical.map((item) => {
            return {
              name: item.product_name,
              value: item.count,
            };
          });
          this.productList = res.data.usersGroupCount.map((item) => {
            return {
              name: item.userGroup,
              value: item.count,
            };
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.home-page {
  width: 100%;
  min-width: 1150px;
  .data-display {
    width: 100%;
    display: flex;
    justify-content: center;
    .sum {
      width: 3.6rem;
      min-width: 200px;
      height: 60px;
      height: 1.5rem;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      margin: 0 0.125rem;
      .sum-circle {
        width: 0.8125rem;
        height: 0.8125rem;
        border-radius: 40px;
        display: flex;
        img {
          margin: auto;
          width: 0.625rem;
        }
      }
      .sum-number {
        span {
          color: #666666;
          font-size: 0.35rem;
        }
        p {
          color: #ccc;
          font-size: 14px;
        }
      }
    }
  }

  .visual-container {
    display: flex;
    justify-content: center;
    margin-top: 0.25rem;
    .region-info {
      width: 11.2rem;
      min-width: 630px;
      border: 1px solid hsl(0deg 0% 91.37%);
      border-radius: 13px;
      margin-right: 0.25rem;

      .day {
        width: 100%;
        height: 0.75rem;
        background-color: hsl(0deg 0% 97.65%);
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top-left-radius: 13px;
        border-top-right-radius: 13px;
        .day-text {
          font-size: 0.225rem;
          font-weight: 700;
          color: hsl(0deg 0% 48.63%);
          margin-left: 0.125rem;
        }
        .radio-box {
          margin-right: 4rem;
        }
      }
      .map-table {
        width: 95%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        // border: 1px solid #dbdbdb;
        border-bottom: none;
        .table-message {
          width: 100%;
          display: flex;
          .number {
            width: 18%;
            height: 0.6875rem;
            border-bottom: 1px solid #dbdbdb;
            border-right: 1px solid #dbdbdb;
            line-height: 0.6875rem;
            padding-left: 0.0625rem;
            font-size: 0.175rem;
          }
          .region {
            width: 18%;
            height: 0.6875rem;
            border-bottom: 1px solid #dbdbdb;
            border-right: 1px solid #dbdbdb;
            line-height: 0.6875rem;
            padding-left: 0.0625rem;
            font-size: 0.175rem;
          }
          .user-number {
            width: 18%;
            height: 0.6875rem;
            border-bottom: 1px solid #dbdbdb;
            border-right: 1px solid #dbdbdb;
            line-height: 0.6875rem;
            padding-left: 0.0625rem;
            font-size: 0.175rem;
          }
          .user-ratio {
            width: 18%;
            height: 0.6875rem;
            border-bottom: 1px solid #dbdbdb;
            border-right: 1px solid #dbdbdb;
            line-height: 0.6875rem;
            padding-left: 0.0625rem;
            font-size: 0.175rem;
          }
          .ratio {
            width: 28%;
            height: 0.6875rem;
            border-bottom: 1px solid #dbdbdb;
            line-height: 0.6875rem;
            padding-left: 0.0625rem;
            font-size: 0.175rem;
          }
        }
      }
    }
    .statistical {
      width: 7.4rem;
      height: 100%;
      min-width: 420px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      .circl-one {
        width: 100%;
        min-width: 6.25rem;
        height: 5rem;
        background-color: #fff;
        border: 1px solid hsl(0deg 0% 91.37%);
        border-radius: 13px;
        .register-circl {
          width: 100%;
          height: 0.75rem;
          background-color: hsl(0deg 0% 97.65%);
          border-top-left-radius: 13px;
          border-top-right-radius: 13px;
          display: flex;
          align-items: center;
          .title {
            margin-left: 0.125rem;
            font-weight: 700;
            font-size: 0.225rem;
            color: hsl(0deg 0% 48.63%);
          }
        }
        .circl-info {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
      .circl-two {
        width: 100%;
        min-width: 6.25rem;
        height: 5rem;
        background-color: #fff;
        border: 1px solid hsl(0deg 0% 91.37%);
        border-radius: 13px;
        margin: 0.125rem 0;
        .product-circl {
          width: 100%;
          height: 0.75rem;
          background-color: hsl(0deg 0% 97.65%);
          border-top-left-radius: 13px;
          border-top-right-radius: 13px;
          display: flex;
          align-items: center;
          .title {
            margin-left: 0.125rem;
            font-weight: 700;
            font-size: 0.225rem;
            color: hsl(0deg 0% 48.63%);
          }
        }
        .product-table {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
      // .client-table {
      //   width: 100%;
      //   min-width: 6.25rem;
      //   background-color: #fff;
      //   border: 1px solid hsl(0deg 0% 91.37%);
      //   border-radius: 13px;
      //   margin: 0.125rem 0;
      //   .product-circl {
      //     width: 100%;
      //     height: 0.75rem;
      //     background-color: hsl(0deg 0% 97.65%);
      //     border-top-left-radius: 13px;
      //     border-top-right-radius: 13px;
      //     display: flex;
      //     align-items: center;
      //     .title {
      //       margin-left: 0.125rem;
      //       font-weight: 700;
      //       font-size: 0.225rem;
      //       color: hsl(0deg 0% 48.63%);
      //     }
      //   }
      //   .table-info {
      //     width: 90%;
      //     // height: 4.025rem;
      //     border: 1px solid #dbdbdb;
      //     margin: 0.125rem auto;
      //     display: flex;
      //     flex-direction: column;
      //     border-bottom: none;
      //     .table-title {
      //       width: 100%;
      //       display: flex;

      //       .title-text {
      //         width: 100%;
      //         height: 0.625rem;
      //         border-bottom: 1px solid #ccc;
      //         border-right: 1px solid #ccc;
      //         line-height: 0.625rem;
      //         padding-left: 0.1875rem;
      //         font-size: 0.175rem;

      //         .dot {
      //           display: inline-block;
      //           width: 0.075rem;
      //           height: 0.075rem;
      //           border-radius: 20px;
      //           margin: 0 0.0625rem;
      //         }
      //       }
      //       .title-count {
      //         width: 20%;
      //         height: 0.625rem;
      //         border-bottom: 1px solid #ccc;
      //         border-right: 1px solid #ccc;
      //         line-height: 0.625rem;
      //         padding-left: 0.0625rem;
      //         font-size: 0.175rem;
      //       }
      //       .title-duty {
      //         width: 20%;
      //         height: 0.625rem;
      //         border-bottom: 1px solid #ccc;
      //         line-height: 0.625rem;
      //         padding-left: 0.0625rem;
      //         font-size: 0.175rem;
      //       }
      //     }
      //   }
      // }
    }
  }
}

.el-radio-button .el-radio-button__inner {
  width: 80px;
}
.el-progress {
  margin-top: 20px;
}

.cricl-background-one {
  background-color: hsl(220.25deg 92.94% 66.67%);
}
.cricl-background-two {
  background-color: hsl(156.19deg 61.76% 60%);
}
.cricl-background-three {
  background-color: hsl(31.17deg 100% 64.9%);
}
.cricl-background-four {
  background-color: hsl(197.01deg 76.97% 67.65%);
}
.cricl-background-five {
  background-color: hsl(335.29deg 100% 80%);
}

.softBgcOne {
  background-color: #8167f5;
}
.softBgcTwo {
  background-color: #51d351;
}
.softBgcThree {
  background-color: #52c1f5;
}
</style>
