<template>
  <!-- 新增编辑发票开具信息 -->
  <div class="invoice-add-page">
    <div class="add-header-title">
      <span>开票信息</span>
      <span style="font-size: 0.15rem; margin-right: 0.1875rem"
        ><i style="color: red">*</i>为必填项</span
      >
    </div>
    <div class="info">
      <div class="make-info">
        <div class="title">
          <div class="box"></div>
          <span>开票信息</span>
        </div>
        <div class="make-ipt">
          <el-form ref="form1" :model="form" :rules="rules"  label-width="100px">
            <el-row type="flex">
              <el-col :span="12">
                <el-form-item
                  label="发票开具项目:"
                  prop="project"
                >
                  <el-input
                    placeholder="软件系统"
                    v-model="form.project"
                  ></el-input> </el-form-item
              ></el-col>
              <el-col :span="12">
                <el-form-item
                  label="开具金额:"
                  prop="money"
                >
                  <el-input
                    placeholder="20000.00"
                    v-model="form.money"
                  ></el-input> </el-form-item
              ></el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item
                  label="发票类别:"
                  prop="category"
                >
                  <el-select v-model="form.category" style="width:100%">
                    <el-option
                      label="增值税普通发票"
                      value="zengzhi"
                    ></el-option>
                    <el-option
                      label="增值税普通发票2"
                      value="beijing"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item
                  label="发票形式:"
                
                  prop="shape"
                >
                  <el-radio-group v-model="form.shape">
                    <el-radio-button label="电子发票"></el-radio-button>
                    <el-radio-button label="纸质发票"></el-radio-button>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="24">
                <el-form-item
                  label="公司名称:"
               
                  prop="companyName"
                >
                  <el-input
                    placeholder="发票抬头"
                    v-model="companyName"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item
                  label="税务登记号:"
              
                  prop="revenueNumber"
                >
                  <el-input
                    placeholder="30303060650606063060"
                    v-model="revenueNumber"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item
                  label="开户行:"
               
                  prop="bank"
                >
                  <el-input placeholder="贵州银行" v-model="bank"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item
                  label="银行账号:"
                 
                  prop="accountNumber"
                >
                  <el-input
                    placeholder="165161611616161616"
                    v-model="form.accountNumber"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item
                  label="开户行地址:"
                  prop="site"
                >
                  <el-input
                    placeholder="贵州省贵阳市云岩区新添大道南段"
                    v-model="form.site"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <div class="put-info">
        <div class="title">
          <div class="box"></div>
          <span>收件信息</span>
        </div>
        <div class="put-ipt">
          <el-form ref="form2" :model="form" label-width="100px" :rules="rules"  >
            <el-row>
              <el-col :span="24">
                <el-form-item
                  label="收件信箱:"
                style="width: 70%;margin: auto;"
                  prop="mail"
                >
                  <el-input
                    v-model="form.mail"
                    placeholder="isnumber.123@163.com"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" justify="center" style="margin-top: 0.375rem">
              <el-col :span="6">
                <el-button
                  type="primary"
                  @click="submit('form1', 'form2')"
                  style="width: 1.75rem"
                  >立即创建</el-button
                >
              </el-col>
              <el-col :span="6">
                <el-button @click="resetForm()" style="width: 1.75rem"
                  >重置</el-button
                >
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      radio1: "电子发票",
      form: {
        project: "",
        money: "",
        category: "",
        shape: "",
        companyName: "",
        revenueNumber: [],
        bank: "",
        accountNumber: "",
        site: "",
        mail: "",
      },
      rules: {
        category: [
          {
            required: true,
            message: "请选择发票类型",
            trigger: "blur",
          },
        ],
        shape: [
          {
            required: true,
            message: "请选择形式",
            trigger: "blur",
          },
        ],
        companyName: [
          {
            required: true,
            message: "请输入公司名称/发票抬头",
            trigger: "blur",
          },
        ],
        revenueNumber: [
          { required: true, message: "请输入税务登记号", trigger: "blur" },
        ],

        mail: [{ required: true, message: "请填写邮箱", trigger: "blur" }],
      },
    };
  },
  methods: {
    submit() {
      // eslint-disable-next-line
      const form1 = new Promise((resolve, _reject) => {
        this.$refs["form1"].validate((valid) => {
          if (valid) resolve();
        });
      });
      // eslint-disable-next-line
      const form2 = new Promise((resolve, _reject) => {
        this.$refs["form2"].validate((valid) => {
          if (valid) resolve();
        });
      });

      Promise.all([form1, form2]).then(() => {
        console.log("验证通过,提交表单");
      });
    },
    resetForm() {
      this.$nextTick(() => {
        this.$refs['form1','form2'].resetFields();
      });
    },
  },
};
</script>
  
  <style lang="less" scoped>
.invoice-add-page {
  width: 92%;
  height: 85%;
  min-height: 700px;
  margin: auto;
  border: 1px solid #fff;
  border-radius: 13px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  .add-header-title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 0.75rem;
    line-height: 0.75rem;
    font-size: 0.225rem;
    background-color: #f9f9f9;
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
    span {
      margin-left: 0.25rem;
    }
  }
  .info {
    width: 100%;
    height: 98%;
    .make-info {
      width: 95%;
      height: 60%;
      min-height: 500px;
      margin: auto;
      .title {
        height: 15%;
        display: flex;
        align-items: center;
        .box {
          width: 5px;
          height: 20px;
          background-color: #409eff;
          margin: 0 0.125rem;
        }
        span {
          font-size: 0.2rem;
          color: #666;
        }
      }
      .make-ipt {
        width: 30%;
        height: 80%;
        margin: auto;
      }
    }
    .put-info {
      width: 95%;
      height: 30%;
      min-height: 200px;
      margin: auto;
      .title {
        height: 15%;
        display: flex;
        display: flex;
        align-items: center;
        .box {
          width: 5px;
          height: 20px;
          background-color: #409eff;
          margin: 0 0.125rem;
        }
        span {
          font-size: 0.2rem;
          color: #666;
        }
      }
      .put-ipt {
        width: 44%;
        height: 80%;
        margin: auto;
      }
    }
  }
}
</style>