import request from "@/utils/request";

//首页金额数据
export function getHomeNumber(params) {
  return request({
    url: "/admin/statistics",
    method: "get",
    params,

  });
}

//首页地图数据

export function getMap(){
  return request({
    url:'/admin/statistics',
    method:'get'
  })
}