//产品管理
import request from "@/utils/request";

// 获取产品管理数据
export function getProductData(params) {
  return request({
    url: "adminProduct/statistics",
    method: "get",
    params,
  });
}
//产品列表
export function getProductList(params) {
  return request({
    url: "/adminProduct/list",
    method: "get",
    params,
  });
}

/**更新产品管理列表数据 */
export function updateProduct(data) {
  return request({
    url: "/adminProduct/updateProduct",
    method: "post",
    data,
  });
}
//产品详情
export function getProductInfoData(productId) {
  return request({
    url: "/adminProduct/details?productId=" + productId,
    method: "get",
  });
}

//新增产品
export function AddProduct(data) {
  return request({
    url: "/adminProduct/createProduct",
    method: "post",
    data,
    Headers: {
      "Content-Type": "application/json",
    },
  });
}
//删除产品列表数据
export function delProductData(productId) {
  return request({
    url: "/adminProduct/delete?productId=" + productId,
    method: "post",
    params: {},
  });
}

//版本发布
export function versionRelease(data) {
  return request({
    url: "/adminProduct/versionRelease",
    method: "post",
    data,
    Headers: {
      "Content-Type": "application/json",
    },
  });
}

//发布记录
export function releaseHistory(data) {
  return request({
    url: "/adminProduct/releaseHistory",
    method: "post",
    data,
    Headers: {
      "Content-Type": "application/json",
    },
  });
}

//订单记录

export function productOrders(data) {
  return request({
    url: "/adminOrders/productOrders",
    method: "post",
    data,
    Headers: {
      "Content-Type": "application/json",
    },
  });
}
