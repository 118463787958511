//管理员列表
import request from "@/utils/request";

// 查询用户管理列表数据
export function searchAdminData(params) {
  return request({
    url: "/adminUser/adminList",
    method: "post",
    data: params,
    Headers: {
      "Content-Type": "application/json",
    },
  });
}

//新增管理员

export function AddAdminData(params) {
  return request({
    url: "/adminUser/createAdminUser",
    method: "post",
    data: params,
    Headers: {
      "Content-Type": "application/json",
    },
  });
}

//删除管理员

export  function  delAdmin(adminUserId)  {
  return  request({
      url:`adminUser/adminDelete?adminUserId=${adminUserId}`,
      method:  "post",
  });
}
//更新管理员

export function updateAdmin(data) {
  return request({
    url: "/adminUser/adminUpdate",
    method: "post",
    data,
  });
}
