/**
 * Application configuration
 */
import store from "@/store";
import { getToken } from "@/utils";

// Initial project configuration
export function initAppConfigStore() {
  // 加载用户信息
  getToken() && store.dispatch("fetchUserInfo");
}
