<template>
  <!-- <h1>新增产品</h1> -->

  <div class="add-product">
    <div class="add-header-title">
      <span>产品信息</span>
      <span style="font-size: 0.15rem; margin-right: 0.1875rem"
        ><i style="color: red">*</i>为必填项</span
      >
    </div>

    <div class="ipt-text">
      <div class="title">
        <div class="box"></div>
        <span>产品信息</span>
      </div>
      <div class="ipt">
        <el-form ref="formRef" :rules="rules" :model="form" label-width="80px">
          <el-form-item label="产品名称：" required label-width="100">
            <el-input
              v-model="form.name"
              placeholder="请输入内容"
              style="width: 3.75rem"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="用户群体："
            required
            label-width="100"
            prop="userGroup"
          >
            <el-radio-group v-model="form.userGroup">
              <el-radio label="1">个体用户</el-radio>
              <el-radio label="2">企事业单位</el-radio>
              <el-radio label="3">政府部门</el-radio>
              <el-radio label="4">注册机专用</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="销售定价："
            :precision="2"
            label-width="100"
            required
            prop="price"
          >
            <el-input-number
              v-model="form.price"
              placeholder="请输入内容"
              style="width: 3.75rem"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="是否上架：" label-width="100" prop="onOffStatus">
            <el-switch
              v-model="form.onOffStatus"
              :active-value="1"
              :inactive-value="0"
            ></el-switch>
          </el-form-item>

          <el-form-item label="软件介绍：" label-width="100" prop="">
            <el-input
              type="textarea"
              :rows="6"
              v-model="form.softwareDescription"
              placeholder="请输入内容"
              style="width: 3.75rem"
            ></el-input>
          </el-form-item>
          <el-form-item label-width="100">
            <el-button type="primary" @click="onSubmit" style="width: 1.8rem"
              >提交</el-button
            >
            <el-button style="width: 1.8rem">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { AddProduct } from "@/api/product";
import { ref } from "vue";
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router";
const rules = {
  userGroup: [{ required: true, message: "请选择用户群体", trigger: "blur" }],
  price: [{ required: true, message: "请输入价格", trigger: "blur" }],
  name: [{ required: true, message: "请输入产品名", trigger: "blur" }],
};
const router = useRouter();
const formRef = ref(null);
const form = ref({
  name: "",
  userGroup: "1",
  price: 0,
  onOffStatus: 1,
  softwareDescription: "",
});
const onSubmit = async () => {
  await formRef.value.validate();
  const { msg } = await AddProduct(form.value);
  ElMessage({
    message: msg,
    type: "success",
    duration: 2000,
  });
  router.back();
};
</script>

<style lang="less" scoped>
.add-product {
  width: 92%;
  height: 85%;
  min-height: 700px;
  margin: auto;
  border: 1px solid #fff;
  border-radius: 13px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);

  .add-header-title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 0.75rem;
    line-height: 0.75rem;
    font-size: 0.225rem;
    background-color: #f9f9f9;
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
    span {
      margin-left: 0.25rem;
    }
  }

  .ipt-text {
    width: 100%;
    height: 80%;
    .title {
      display: flex;
      align-items: center;
      height: 20%;
      .box {
        width: 0.0625rem;
        height: 0.3125rem;
        background-color: #409eff;
        margin-left: 0.625rem;
      }
      span {
        margin-left: 0.125rem;
        font-size: 0.2rem;
      }
    }

    .ipt {
      width: 40%;
      height: 90%;
      margin: auto;
    }
  }
}
</style>
