<!-- 订单管理 -->
<template>
  <div class="order-content">
    <div class="header-title"><span>订单管理</span></div>
    <div class="order-table mt-2 px-2">
      <el-table
        ref="multipleTable"
        :data="tableData"
        :header-cell-style="{
          background: '#f2f2f2',
          color: '#101010',
          'text-align': 'center',
          height: '10px',
        }"
        border
        :show-overflow-tooltip="true"
        v-loading="loading"
        style="width: 100%"
      >
        <el-table-column label="序号" width="100" type="index" align="center">
        </el-table-column>

        <el-table-column
          prop="ordersId"
          label="订单编号"
          width="250"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="orderStatus" label="订单状态" width="200" >
          <template #default="{ row }">
            <div
              :style="{ color: row.orderStatus == '2' ? '#29D100' : 'red',textAlign:  'center' }"
              
            >
              {{ row.orderStatus == 2 ? "• 激活" : "• 已过期" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="productName"
          label="订单产品"
          width="250"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="authorizationDuration"
          label="订购时长(年)"
          width="120"
        >
        </el-table-column>
        <el-table-column
          prop="payTime"
          label="订购时间"
          width="200"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="invoicesStatus" label="发票状态" width="100">
          <template #default="{ row }">
            <div
              :style="{ color: row.invoicesStatus == '2' ? '#29D100' : 'red',textAlign:  'center' }"
            >
              {{ row.invoicesStatus == 2 ? "• 已开" : "• 未开" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="280" align="center" fixed="right">
          <template #default="{ row }">
            <el-button
              size="small"
              plain
              type="primary"
              @click="infoData(row.ordersId)"
              >详情</el-button
            >
            <el-button
              size="small"
              plain
              type="primary"
              @click="gotoPaper(row)"
              v-if="row.invoicesStatus === 1"
              >开票</el-button
            >
            <el-popconfirm
              title="确定删除吗?"
              @Confirm="confirmDel(row.ordersId)"
              confirm-button-text="确定"
              cancel-button-text="取消"
            >
              <template #reference>
                <el-button size="small" plain type="warning">删除</el-button>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-row class="order-btn">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </el-row>
  </div>
</template>

<script>
import { getOrderData, delOrderData } from "@/api/order";
export default {
  data() {
    return {
      records: "",
      pageSize: 10,
      page: 1,
      total: 0,
      tableData: [],
      loading: false,
      ordersId: "",
      orderId: "",
    };
  },

  mounted() {
    this.getOrderData();
  },
  methods: {
    // 页码改变
    handleCurrentChange(e) {
      this.page = e;
      this.getOrderData();
    },
    // 数量改变
    handleSizeChange(e) {
      this.pageSize = e;
      this.getOrderData();
    },
    //获取列表数据方法
    async getOrderData() {
      const { pageSize, page } = this;
      this.loading = true;
      const {
        data: { rows, records },
      } = await getOrderData({ pageSize, page });
      this.tableData = rows;
      this.total = records; //分页总数
      this.loading = false;
    },
    // 页面跳转
    gotoPaper({ ordersId, totalAmount }) {
      this.$router.push({
        path: "/orderPage/mackInvoice",
        query: { orderId: ordersId, price: totalAmount },
      });
    },
    // 详情
    infoData(ordersId) {
      this.$router.push({
        path: "/orderPage/detailPage",
        query: { id: ordersId },
      });
    },

    //删除数据
    confirmDel(ordersId) {
      delOrderData(ordersId)
        .then((res) => {
          if (res.status == 200) {
            this.$message.success("删除成功");
            //删除成功 回调列表接口
            this.getOrderData();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {
          this.$message.error("删除失败！");
        });
    },
  },
};
</script>

<style lang="less" scoped>
.order-content {
  width: 92%;
  height: 85%;
  min-height: 600px;
  margin: auto;
  border: 1px solid #fff;
  border-radius: 13px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);

  .header-title {
    width: 100%;
    height: 0.75rem;
    line-height: 0.75rem;
    font-size: 0.225rem;
    background-color: #f9f9f9;
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
    span {
      margin-left: 0.25rem;
    }
  }
  .order-form {
    width: 100%;
    height: 8%;
    .demo-form-inline {
      display: flex;
      flex-wrap: wrap;
      margin-left: 0.7rem;
      margin-top: 30px;
    }
  }

  .order-table {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .order-btn {
    width: 100%;
    display: flex;
    margin-top: 30px;
    justify-content: center;
  }
}
</style>
