<template>
  <!-- 管理员管理 -->
  <div class="admin-page">
    <div class="header-title"><span>管理员管理</span></div>
    <div class="add-ipt">
      <el-button type="primary" class="newAdd" @click="gotoAdd"
        >新增管理员</el-button
      >
      <el-form
        ref="formInline"
        :inline="true"
        :model="formInline"
        class="demo-form-inline"
        :rules="rules"
      >
        <el-form-item prop="adminName">
          <el-input
            v-model="formInline.adminName"
            placeholder="账号"
          ></el-input>
        </el-form-item>
        <el-form-item prop="mobile">
          <el-input
            v-model="formInline.mobile"
            placeholder="手机号"
            maxlength="11"
          ></el-input>
        </el-form-item>
        <el-form-item prop="status">
          <el-select v-model="formInline.status" placeholder="请选择状态">
            <el-option label="启用" :value="1"> </el-option>
            <el-option label="禁用" :value="2"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleQuery()">查询</el-button>
          <el-button @click="resetForm('formInline')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 98%; margin: auto"
      border
      :header-cell-style="{
        background: '#f2f2f2',
        color: '#101010',
        'text-align': 'center',
        height: '10px',
      }"
      v-loading="loading"
    >
      <el-table-column
        prop="adminName"
        label="管理员名"
        width="200"
        align="center"
      >
      </el-table-column>
      <el-table-column label="操作权限" width="250" align="center">
        <template #default="{ row }">
          <div class="flex flex-row whitespace-normal">
            {{ row.roleVOS.map((v) => v.name).join("、") }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="status"
        label="账号状态"
        width="180"
        align="center"
      >
        <template #default="{ row }">
          <el-switch
            v-if="row.status"
            v-model="row.status"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-value="1"
            :inactive-value="2"
            :disabled="!row.status"
            @change="changeStatus($event, row)"
          >
          </el-switch>
        </template>
      </el-table-column>

      <el-table-column prop="mobile" label="手机" width="200" align="center">
      </el-table-column>
      <el-table-column
        prop="password"
        label="密码"
        width="200"
        show-password
        align="center"
      >
        <template #default>
          <div>**********</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="updatedTime"
        label="最后登录"
        width="250"
        align="center"
      >
      </el-table-column>

      <el-table-column label="操作" width="267" align="center" fixed="right">
        <template #default="{ row }">
          <el-button size="small" type="primary" plain @click="toUpdate(row)">
            更新
          </el-button>
          <el-popconfirm
            title="确定删除吗?"
            @Confirm="confirmDel(row.id)"
            confirm-button-text="确定"
            cancel-button-text="取消"
          >
            <template #reference>
              <el-button size="small" plain type="warning">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-row class="admin-btn">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="formInline.page"
        :page-size="formInline.pageSize"
        background
        layout="total, sizes, prev, pager, next"
        :total="total"
      />
    </el-row>
  </div>
</template>

<script>
import { searchAdminData, delAdmin, updateAdmin } from "@/api/admin";
export default {
  data() {
    return {
      loading: false,
      formInline: {
        adminName: "",
        mobile: "",
        status: "",
        page: 1,
        pageSize: 10,
      },
      rules: {
        mobile: [
          {
            pattern: /^(1[3-9])\d{9}/,
            message: "手机号码格式不正确",
            trigger: "blur",
          },
        ],
      },
      adminList: "",
      tableData: [],
      total: 0,
    };
  },
  mounted() {
    this.loading = true;
    this.onSubmit();
  },
  methods: {
    // 页码改变
    handleCurrentChange(e) {
      this.formInline.page = e;
      this.onSubmit();
    },
    // 数量改变
    handleSizeChange(e) {
      this.formInline.pageSize = e;
      this.onSubmit();
    },
    onSubmit() {
      searchAdminData(this.formInline)
        .then((res) => {
          this.adminList = res.data;
          if (res.status === 200 && res.success) {
            const { rows, records } = res.data;
            this.tableData = rows;
            this.total = records; //分页总数
            this.loading = false;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    handleQuery() {
      this.formInline.page = 1;
      this.onSubmit();
    },
    resetForm() {
      this.$nextTick(() => {
        this.$refs.formInline.resetFields();
        this.handleQuery();
      });
    },
    //删除数据
    confirmDel(adminUserId) {
      delAdmin(adminUserId).then((res) => {
        if (res.status == 200) {
          this.$message.success("删除成功");
          //删除成功 回调列表接口
          this.onSubmit();
          this.handleCurrentChange();
          this.handleSizeChange();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    gotoAdd() {
      this.$router.push("/adminPage/addAdmin");
    },
    toUpdate(row) {
      this.$router.push({
        path: "/adminPage/redactAdmin",
        query: { ...row, roleId: row.roleVOS.map((v) => v.id) },
      });
    },
    //状态更改
    async changeStatus(status, { id }) {
      const result = await updateAdmin({
        userId: id,
        status,
      });
      this.$message({
        message: result.msg,
        type: "success",
      });
      this.onSubmit();
    },
  },
};
</script>

<style lang="less" scoped>
.admin-page {
  width: 92%;
  height: 85%;
  margin: auto;
  border: 1px solid #fff;
  border-radius: 13px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  .header-title {
    width: 100%;
    height: 0.75rem;
    line-height: 0.75rem;
    font-size: 0.225rem;
    background-color: #f9f9f9;
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
    span {
      margin-left: 0.25rem;
    }
  }
  .add-ipt {
    display: flex;
    flex-wrap: wrap;
    margin: 0.25rem;
    .newAdd {
      margin-right: 0.5rem;
    }
  }

  .admin-btn {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
</style>
