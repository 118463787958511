<!-- 订单详情页 -->
<template>
  <div class="order-content">
    <div class="header-title"><span>订单详情</span></div>
    <div class="serial-number">
      <div class="order-number-info">
        <div class="top-box">
          <div class="number-title">订单编号:{{ ordersId }}</div>
          <div class="change-Btn">
            <!-- <el-button plain>修改发票</el-button>
            <el-button plain>备注订单</el-button>
            <el-button plain>关闭订单</el-button> -->
          </div>
        </div>
        <div class="btm-info">
          <div class="table-info">
            <div class="order-id">
              <div class="id">
                <span>授权设备ID:</span> <span>{{ deviceId }}</span>
              </div>
              <div class="id-time">
                <span>授权时长:</span> <span>{{ authorizationDuration }}</span>
              </div>
              <div class="up-time">
                <span>提交时间:</span> <span>{{ payTime }}</span>
              </div>
            </div>
            <div class="order-id">
              <div class="id">
                <span>是否开发票:</span> <span>{{ invoicesStatus }}</span>
              </div>
              <div class="id-time">
                <span>注册单位:</span> <span>{{ companyName }}</span>
              </div>
              <div class="up-time">
                <span>备注:</span> <span>{{ extand }}</span>
              </div>
            </div>
          </div>
          <div class="state">
            <div class="order-state">
              <div class="title">订单状态</div>
              <div class="pay">
                <div class="payCri"></div>
                <span v-if="orderStatus === 2" style="color: #3bf90c"
                  >已支付</span
                >
                <span v-else-if="orderStatus === 4" style="color: #ccc"
                  >已过期</span
                >
              </div>
            </div>
            <div class="order-money">
              <div class="title">订单金额</div>
              <div class="money">
                <span>¥</span><span>{{ totalAmount }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mack-invoice">
      <div class="product-title"><div class="title">产品列表</div></div>
      <div class="product-list">
        <el-table
          :cell-style="{ height: '1.1rem' }"
          :data="tableData"
          border
          style="width: 100%; height: 100%"
          row-style="{height:0.625rem}"
          :header-cell-style="{ height: '0.8rem' }"
        >
          <el-table-column
            prop="date"
            label="序号"
            min-width="10%"
            align="center"
            type="index"
          >
          </el-table-column>
          <el-table-column prop="productName" label="产品名称" min-width="20%">
          </el-table-column>
          <el-table-column
            prop="unitPrice"
            label="价格 (元/年)"
            min-width="10%"
          >
          </el-table-column>
          <el-table-column
            prop="authorizationDuration"
            label="授权时长 (年)"
            min-width="10%"
          >
            <template #default>
              <div>{{ authorizationDuration }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="unitPrice" label="小计" min-width="20%">
          </el-table-column>
        </el-table>
        <div class="subtotal-text">
          <div class="title">
            <span class="all">合计:</span>
            <span class="money"><i>¥</i>{{ totalAmount }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getOrderInfoData } from "@/api/order";
export default {
  data() {
    return {
      ordersId: "",
      deviceId: "",
      authorizationDuration: "",
      invoicesStatus: "",
      companyName: "",
      extand: "",
      payTime: "",
      orderStatus: "",
      totalAmount: "",
      tableData: [],
    };
  },
  created() {
    this.$route.query.id && this.getOrderInfoData(this.$route.query.id);
  },
  methods: {
    async getOrderInfoData() {
      const { data } = await getOrderInfoData(this.$route.query.id);
      //
      this.ordersId = data.id;
      this.deviceId = data.deviceId;
      this.authorizationDuration = data.authorizationDuration;
      this.invoicesStatus = data.invoicesStatus;
      this.companyName = data.companyName;
      this.extand = data.extand;
      this.totalAmount = data.totalAmount;
      this.payTime = data.payTime;
      this.orderStatus = data.orderStatus;

      this.tableData = data.productlist;
    },
  },
};
</script>

<style lang="less" scoped>
.order-content {
  width: 92%;
  height: 85%;
  min-height: 7.5rem;
  margin: auto;
  border: 1px solid #fff;
  border-radius: 13px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);

  .header-title {
    width: 100%;
    height: 0.75rem;
    line-height: 0.75rem;
    font-size: 0.225rem;
    background-color: #f9f9f9;
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
    span {
      margin-left: 0.25rem;
    }
  }
  .serial-number {
    width: 100%;
    height: 40%;
    .order-number-info {
      height: 100%;
      .top-box {
        height: 30%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        .number-title {
          font-size: 0.225rem;
          margin-left: 0.45rem;
          font-weight: 800;
        }
        .change-Btn {
          margin-right: 0.45rem;
          .el-button {
            font-size: 0.1625rem;
          }
        }
      }
      .btm-info {
        width: 100%;
        height: 50%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;
        .table-info {
          width: 70%;
          height: 100%;
          border: 1px solid #dbdbdb;
          overflow: hidden;
          .order-id {
            height: 50%;
            border-bottom: 1px solid #dbdbdb;
            display: flex;
            align-items: center;
            .id {
              width: 35%;
              height: 100%;
              display: flex;
              align-items: center;
              span:nth-child(1) {
                width: 70%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 0.2rem;
                color: #666;
                border-right: 1px solid #dbdbdb;
              }
              span:nth-child(2) {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                font-size: 0.2rem;
                border-right: 1px solid #dbdbdb;
                margin-left: 0.125rem;
              }
            }
            .id-time {
              width: 35%;
              height: 100%;
              display: flex;
              align-items: center;
              span:nth-child(1) {
                width: 70%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 0.2rem;
                color: #666;
                border-right: 1px solid #dbdbdb;
              }
              span:nth-child(2) {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                font-size: 0.2rem;
                border-right: 1px solid #dbdbdb;
                margin-left: 0.125rem;
              }
            }
            .up-time {
              width: 30%;
              height: 100%;
              display: flex;
              align-items: center;
              span:nth-child(1) {
                width: 70%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 0.2rem;
                color: #666;
                border-right: 1px solid #dbdbdb;
              }
              span:nth-child(2) {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                font-size: 0.2rem;
                margin-left: 0.125rem;
              }
            }
          }
          .order-id:nth-child(2) {
            border-bottom: none;
          }
        }
        .state {
          width: 20%;
          height: 100%;
          display: flex;
          justify-content: space-around;
          align-items: center;
          flex-wrap: wrap;
          .order-state {
            .title {
              font-size: 0.165rem;
              text-align: right;
              color: #999999;
            }
            .pay {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-top: 0.1875rem;
              .payCri {
                width: 0.1875rem;
                height: 0.1875rem;
                background-color: #3bf90c;
                line-height: 15px;
                border-radius: 50%;
              }
              span {
                font-size: 0.225rem;
                color: green;
                margin-left: 0.125rem;
              }
            }
          }
          .order-money {
            .title {
              font-size: 0.165rem;
              text-align: right;
              color: #999999;
            }
            .money {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-top: 0.1875rem;

              span {
                font-size: 0.225rem;
              }
            }
          }
        }
      }
    }
  }

  .mack-invoice {
    width: 100%;
    height: 50%;
    .product-title {
      width: 95%;
      height: 15%;
      font-weight: 700;
      background-color: #f5f5f5;
      margin: auto;
      border: 1px solid #e7e7e7;
      border-bottom: none;
      .title {
        display: flex;
        align-items: center;
        height: 100%;
        font-size: 0.225rem;
        margin-left: 0.125rem;
      }
    }
    .product-list {
      height: 70%;
      width: 95%;
      background-color: #dbdbdb;
      margin: auto;
    }
    .subtotal-text {
      width: 100%;
      height: 20%;
      background-color: #f5f5f5;
      margin: auto;
      border: 1px solid #e7e7e7;
      border-top: none;
      .title {
        width: 95%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 100%;
        font-size: 0.225rem;
        margin-left: 0.125rem;
        .all {
          color: #666;
          font-weight: 800;
        }
        .money {
          color: red;
          font-weight: 800;
        }
      }
    }
  }
}
</style>
