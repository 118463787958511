// 订单管理

import request from "@/utils/request";

// 获取订单数据
export function getOrderData(params) {
  return request({
    url: "/adminOrders/list",
    method: "get",
    params,
  });
}



// 获取订单详情
export function getOrderInfoData(ordersId) {
  return request({
    url: "/adminOrders/orderInfo?ordersId=" + ordersId,
    method: "post",
  });
}

/**删除数据 */
export function delOrderData(ordersId) {
  return request({
    url: "/adminOrders/delete?ordersId=" + ordersId,
    method: "post",
    params: {
    },
  });
}