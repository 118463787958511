// 角色模块

import request from "@/utils/request";

// 获取用户详情
export function getRoleList(params) {
  return request({
    url: "/adminRole/list",
    method: "get",
    params,
  });
}
