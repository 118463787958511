import { menusConfig } from "@/config/menu";
import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import { superCode } from "@/config/menu";
import { getUserInfo } from "@/api/user";
import { userLogin } from "@/api/login";
import router from "../router";

export default createStore({
  state: {
    token: "",
    userInfo: {},
  },
  mutations: {
    SET_USER_TOKEN: (state, payload) => {
      state.token = payload;
    },
    LOG_OUT: (state) => {
      state.token = "";
      state.userInfo = {};
      sessionStorage.clear();
    },
    SET_USER_INFO: (state, info) => {
      state.userInfo = info;
    },
  },
  getters: {
    // 根据角色获取菜单
    getMenuList: (state) => {
      let menus = [];
      // 超级管理员
      if (state.userInfo?.roleId?.includes(superCode)) {
        menus = menusConfig;
      } else {
        // 普通权限
        menus = menusConfig.filter((item) =>
          state.userInfo?.roleId?.includes(item.value)
        );
      }
      // 默认将第一个加入到菜单中
      let dashboard = menusConfig[0];
      if (menus.findIndex((v) => v.name === dashboard.name) === -1)
        menus.unshift(menusConfig[0]);
      return menus;
    },
  },
  actions: {
    setToken({ commit }, payload) {
      commit("SET_USER_TOKEN", payload);
    },
    doLogout({ commit }) {
      commit("LOG_OUT");
      router.push("/login");
    },
    setUseuInfo({ commit }, payload) {
      commit("SET_USER_INFO", payload);
    },
    // 获取用户信息
    async fetchUserInfo({ commit }) {
      try {
        const { data } = await getUserInfo();
        commit("SET_USER_INFO", data);
        return true;
      } catch (err) {
        return false;
      }
    },
    // 用户登录
    async doLogin({ commit }, param) {
      try {
        const { data } = await userLogin(param);
        sessionStorage.setItem("TOKEN", data.userToken);

        commit("SET_USER_INFO", data);
        return data;
      } catch (err) {
        return false;
      }
    },
  },

  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => {
          const tmpStr = sessionStorage.getItem(key);
          if (tmpStr == null || tmpStr === undefined || tmpStr.length <= 0) {
            return {};
          }
          return tmpStr;
        },
        setItem: (key, value) => {
          if (value == null || value === undefined) {
            return;
          }

          sessionStorage.setItem(key, value);
        },
        removeItem: (key) => {
          sessionStorage.removeItem(key);
        },
      },
    }),
  ],
});
