<!-- 用户管理 -->
<template>
  <div class="user-content">
    <div class="user-manage"><span>用户管理</span></div>
    <div class="search-user">
      <el-form
        ref="formInline"
        :inline="true"
        :model="queryParams"
        class="demo-form-inline"
        style="margin-left: 0.5rem"
      >
        <el-form-item prop="mobile">
          <el-input
            v-model.number="queryParams.mobile"
            maxlength="11"
            placeholder="用户账号/ID/手机号码"
          ></el-input>
        </el-form-item>
        <el-form-item prop="userGroup">
          <el-select v-model="queryParams.userGroup" placeholder="请选择类型">
            <el-option label="个人用户" :value="1"> </el-option>
            <el-option label="企事业单位" :value="2"> </el-option>
            <el-option label="政务部门" :value="3"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="status">
          <el-select v-model="queryParams.status" placeholder="请选择用户状态">
            <el-option label="启用" :value="1"> </el-option>
            <el-option label="禁用" :value="2"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="companyName">
          <el-input
            v-model="queryParams.companyName"
            placeholder="用户单位"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleQuery()">查询</el-button>
          <el-button @click="resetForm('formInline')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="export-data">
      <!-- <el-button>添加标签</el-button>
      <el-button>导出数据</el-button> -->
    </div>
    <div class="user-info">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 95%; margin: auto"
        border
        height="430"
        v-loading="loading"
        :header-cell-style="{
          background: '#f2f2f2',
          color: '#101010',
          'text-align': 'center',
          height: '10px',
        }"
      >
        <el-table-column label="ID编号" width="200" prop="id" align="center">
        </el-table-column>
        <el-table-column
          prop="realName"
          label="用户名"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="mobile"
          label="手机号码"
          width="200"
          align="center"
        >
        </el-table-column>

        <el-table-column
          prop="userGroup"
          label="用户类型"
          width="200"
          align="center"
        >
          <template #default="{ row }">
            <span>
              {{
                row.userGroup == 1
                  ? "个人用户"
                  : row.userGroup == 2
                  ? "企事业单位"
                  : "政务部门"
              }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="status"
          label="用户状态"
          width="150"
          align="center"
        >
          <template #default="{ row }">
            <el-switch
              v-if="row.status"
              @change="changeStatus($event, row)"
              :model-value="row.status"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="2"
              :disabled="!row.status"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column
          prop="orderQuantity"
          label="订单记录"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="consumptionAmount"
          label="消费金额"
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="companyName"
          label="用户单位"
          width="190"
          align="center"
        >
        </el-table-column>

        <el-table-column label="操作" width="261" align="center" fixed="right">
          <template #default="{ row }">
            <el-button
              size="small"
              type="primary"
              plain
              @click="gotoDetail(row)"
            >
              详情
            </el-button>

            <el-button size="small" type="primary" plain @click="toUpdate(row)">
              编辑
            </el-button>
            <el-popconfirm
              title="确定删除吗?"
              @Confirm="confirmDel(row.id)"
              confirm-button-text="确定"
              cancel-button-text="取消"
            >
              <template #reference>
                <el-button size="small" plain type="warning">删除</el-button>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <el-row class="user-btn">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryParams.page"
          :page-size="queryParams.pageSize"
          background
          layout="total, sizes, prev, pager, next"
          :total="total"
        />
      </el-row>
    </div>
  </div>
</template>
<script setup>
import { ElMessage } from "element-plus";
import { searchUsers, delInvoiceData, updateUser } from "@/api/user";
import { nextTick, onMounted, reactive, ref } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();

const total = ref(0);
const loading = ref(false);
const queryParams = reactive({
  mobile: "",
  userGroup: "",
  status: "",
  companyName: "",
  page: 1,
  pageSize: 10,
});
const userList = ref([]); // 存储查询结果
const tableData = ref([]);
const formInline = ref(null);

onMounted(() => {
  getData();
});

const changeStatus = async (status, { id }) => {
  const result = await updateUser({
    id,
    status,
  });
  ElMessage({
    message: result.msg,
    type: "success",
  });
  getData();
};

function handleQuery() {
  queryParams.page = 1;
  getData();
}
//查询
function getData() {
  searchUsers(queryParams).then((res) => {
    userList.value = res.data; // 将查询结果存储到userList中
    const { rows, records } = res.data;
    tableData.value = rows;
    total.value = records;
  });
}

//重置
function resetForm() {
  nextTick(() => {
    formInline.value.resetFields();
    handleQuery();
  });
}
/**分页请求*/
function handleCurrentChange(currentPage = 1) {
  currentPage.value = currentPage;
  getData();
}
//* 设置显示条数 */
function handleSizeChange(val) {
  queryParams.pageSize = val;
  getData();
}
//删除
function confirmDel(userId) {
  delInvoiceData(userId).then((res) => {
    ElMessage({
      message: res.msg,
      type: "success",
    });
    //删除成功 回调列表接口
    getData();
    handleCurrentChange();
  });
}
function toUpdate(row) {
  router.push({
    path: "/userPage/changeUser",
    query: row,
  });
}
function gotoDetail(row) {
  router.push({
    name: "userDetails",
    query: row,
  });
}
</script>

<style lang="less" scoped>
.user-content {
  width: 92%;
  height: 85%;
  min-height: 600px;
  margin: auto;
  border: 1px solid #fff;
  border-radius: 13px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  .user-manage {
    width: 100%;
    height: 0.75rem;
    line-height: 0.75rem;
    font-size: 0.225rem;
    background-color: #f9f9f9;
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
    span {
      margin-left: 0.25rem;
    }
  }

  .search-user {
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    align-items: flex-end;
  }

  .export-data {
    height: 3%;
    display: flex;
    flex-wrap: wrap;
    margin-left: 0.5rem;
  }

  .user-info {
    width: 100%;
    height: 60%;
    min-height: 300px;
  }
  .user-btn {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
</style>
