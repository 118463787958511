<template>
  <div>
    <el-container>
      <el-aside width="200px">
        <div class="user-photo">
          <img src="@/assets/home/picture.jpg" alt="" />
        </div>
        <!-- 菜单 -->
        <el-menu
          router
          :default-active="currentMenu"
          active-text-color="#409EFF"
          text-color="#ccc"
        >
          <el-menu-item
            :index="'/' + i.name"
            v-for="(i, index) in getMenuList"
            :key="index"
          >
            <span>{{ i.menuItem }}</span>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <el-main>
        <div class="nav-top">
          <!-- 面包屑 -->
          <el-breadcrumb router separator="/" class="breadcrumb-text">
            <el-breadcrumb-item
              v-for="(crumb, index) in breadList"
              :key="index"
              :to="{ path: crumb.pathName }"
              >{{ crumb.name }}</el-breadcrumb-item
            >
          </el-breadcrumb>
          <div class="left">
            <div class="user-search"></div>
            <div class="user-message"></div>
          </div>
        </div>
        <router-view />
      </el-main>
    </el-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      input2: "",
      currentMenu: "",
    };
  },
  methods: {
    // 激活父菜单选中
    initActiveMenu() {
      const { path } = this.$route;
      const parentPath = path.split("/").filter((v) => !!v);
      this.currentMenu = `/${parentPath[0]}`;
    },
  },
  watch: {
    $route() {
      // 优化前
      // this.currentMenu = e.path; // e里面的是当前路由的信息
      // 优化后
      this.initActiveMenu();
    },
  },
  created() {
    this.initActiveMenu();
  },
  computed: {
    ...mapGetters(["getMenuList"]),
    breadList() {
      //  获取当前路由信息
      const matched = this.$route.matched;
      // eslint-disable-next-line
      const last = matched[matched.length - 1];
      //  生成面包屑导航
      const list = [];
      // eslint-disable-next-line
      matched.forEach((item, index) => {
        list.push({ name: item.meta.title, pathName: item.path });
      });
      return list;
    },
  },
};
</script>

<style lang="less" scoped>
.user-photo {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  img {
    width: 60px;
    height: 60px;
    border-radius: 10px;
    margin: 50px 0 30px 0;
  }
}
.nav-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px 0 30px 0;
  .breadcrumb-text {
    font-size: 14px;
    color: #999999;
    display: flex;
    height: 100%;
    justify-content: flex-start;
    padding-left: 92px;
    white-space: nowrap;
  }
  @media screen and(max-width:1300px) {
    .breadcrumb-text {
      padding-left: 45px;
    }
  }
  .left {
    display: flex;
    align-items: flex-end;
    width: 40%;
    height: 0.5rem;
    .user-search {
      min-width: 100px;
      margin: 0 50px;
      ::v-deep .el-input__wrapper {
        border-radius: 20px !important;
      }
    }
    .user-message {
      display: flex;
      justify-content: space-around;
      align-items: center;
      color: #999999;
      font-size: 14px;

      span {
        width: 70px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        position: relative;
        .el-icon-bell {
          width: 20px;
          height: 20px;
          background-color: #f56c6c;
          color: #fff;
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          font-size: 13px;
          position: absolute;
          right: 0;
          top: 0;
        }
      }
      span:hover {
        color: #409eff;
        cursor: pointer;
      }
    }
  }
}
.el-main {
  padding: 0 !important;
  height: 100vh !important;
}

.el-input-border-radius {
  border-radius: 20px !important;
}

.el-menu {
  width: 200px;
  border-right: 1px solid transparent !important;
  padding: 60px 0 0 0;
  background-color: transparent;
}
.el-menu-item {
  justify-content: space-around;
  span {
    font-size: 15px;
    font-family: "思源-heavy";
  }
}
</style>
