<template>
  <router-view />
</template>

<script setup>
import * as echarts from "echarts";
import { provide } from "vue";
provide("echarts", echarts);
</script>
<style>
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}
</style>
