<template>
  <div id="china_map_box">
    <div id="china_map" ref="chartRef"></div>
  </div>
</template>
<script setup>
import * as echarts from "echarts";
import "echarts/map/js/china.js";
import { nextTick, onMounted, reactive, ref, unref, watch } from "vue";
const props = defineProps({
  mapList: {
    type: Array,
  },
});
watch(
  () => props.mapList,
  () => setEchartOption(),
  { deep: true }
);
const options = reactive({
  tooltip: {
    triggerOn: "mousemove", //mousemove、click
    padding: 0,
    borderColor: "#999",
    backgroundColor: "rgba(255,255,255,0.9)",
    textStyle: {
      color: "#000000",
      fontSize: 13,
    },

    itemStyle: {
      normal: {
        areaColor: "red",
      },
    },
  },
  visualMap: {
    show: true,
    left: 26,
    bottom: 40,
    itemWidth: 180,
    itemHeight: 20,

    pieces: [
      {
        max: 100,
        color: "hsl(203.93deg 100% 68.04%)",
      },
    ],
  },
  geo: {
    map: "china",
    scaleLimit: {
      min: 1,
      max: 2,
    },
    zoom: 1,

    label: {
      normal: {
        color: "rgba(0,0,0,0.7)",
      },
    },
    itemStyle: {
      normal: {
        borderColor: "rgba(0, 0, 0, 0.2)",
      },
      emphasis: {
        areaColor: "hsl(203.92deg 100% 70%)",
        shadowOffsetX: 0,
        shadowOffsetY: 0,
        borderWidth: 0,
      },
    },
  },
  series: [
    {
      name: "map",
      type: "map",
      geoIndex: 0,
    },
  ],
});
const chartRef = ref(null);
const chartInstance = ref(null);
//初始化中国地图
function initEchartMap() {
  options.series[0].data = props.mapList;
  chartInstance.value = echarts.init(unref(chartRef));
  chartInstance.value.setOption(options);
  // 初始化echarts实例
  //监听浏览器resize事件
  window.addEventListener("resize", function () {
    chartInstance.value.resize();
  });
}
//修改echart配制
function setEchartOption() {
  options.series[0].data = props.mapList;
  chartInstance.value?.setOption(options);
}
onMounted(() => {
  nextTick(() => initEchartMap());
});
</script>

<style>

#china_map_box {
  width: 100%;
  /* height: 100%; */
  min-width: 5rem;
  min-height: 3.75rem;
}
#china_map {
  width: 90%;
  height: 7.5rem;
  margin: 0;
}

</style>
