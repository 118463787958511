import { createRouter, createWebHashHistory } from "vue-router";
import login from "@/views/user-login.vue";
import home from "@/views/user-home.vue";
import homePage from "@/components/HomePage.vue";
//import basicsPage from "@/components/menu/basicsPage.vue";
//import systemView from "@/components/basics/systemView.vue";
//import passwordView from "@/components/basics/passwordView.vue";
//import loginRecord from "@/components/basics/loginRecord.vue";
//import payPort from "@/components/basics/payPort.vue";
import orderPage from "@/components/menu/orderPage.vue";

import invoiceList from "@/components/menu/invoiceList.vue";
import addInvoice from "@/components/invoice/addInvoice.vue";
import invoiceInfo from "@/components/invoice/invoiceInfo.vue";

import userPage from "@/components/menu/userPage.vue";
import changeUser from "@/components/user/changeUser.vue";
import userDetails from "@/components/user/userDetails.vue";

import productPage from "@/components/menu/productPage.vue";
import addProduct from "@/components/product/addProduct.vue";
import editProduct from "@/components/product/editProduct.vue";
import productInfo from "@/components/product/productInfo.vue";

import adminPage from "@/components/menu/adminPage.vue";
import adminInfo from "@/components/admin/adminInfo.vue"; //管理员详情页面
import addAdmin from "@/components/admin/addAdmin.vue"; //添加管理员页面
import redactAdmin from "@/components/admin/redactAdmin.vue"; //编辑管理员页面

import mackInvoice from "@/components/order/mackInvoice.vue";
import detailPage from "@/components/order/DetailPage.vue";

//import announceMent from "@/components/menu/announceMent.vue"; //公告管理
//import putMent from "@/components/announce/putMent.vue"; //新增公告
const routes = [
  {
    path: "/",
    redirect: "login",
  },

  {
    path: "/login",
    name: "login",
    component: login,
    meta: {
      title: "登录",
    },
  },
  {
    path: "/home",
    name: "home",
    component: home,
    meta: {
      title: "系统首页",
    },
    children: [
      {
        path: "/404",
        name: "404",
        component: () => import("@/components/error/NotFound.vue"),
      },
      {
        path: "/home",
        name: "home",
        component: homePage, //首页
        meta: {
          title: "首页",
        },
      },
      // {
      //   path: "/basicsPage", //系统设置
      //   component: basicsPage,
      //   redirect: "/basicsPage/passwordView",
      //   meta: {
      //     title: "系统设置",
      //   },
      //   children: [
      //     // {
      //     //   path: "/basicsPage/index", //系统首页
      //     //   component: systemView,
      //     //   meta: {
      //     //     title: "系统设置",
      //     //   },
      //     // },
      //     {
      //       path: "/basicsPage/passwordView", //修改密码
      //       component: passwordView,
      //       meta: {
      //         title: "修改密码",
      //       },
      //     },

      //     {
      //       path: "/basicsPage/loginRecord", //登录记录
      //       component: loginRecord,
      //       meta: {
      //         title: "登录记录",
      //       },
      //     },
      //     // {
      //     //   path: "/basicsPage/payPort", //产品管理
      //     //   component: payPort,
      //     //   meta: {
      //     //     title: "产品管理",
      //     //   },
      //     // },
      //   ],
      // },
      {
        path: "/orderPage", //订单管理
        redirect: "/orderPage/index",
        meta: {
          title: "订单管理",
        },
        children: [
          {
            path: "/orderPage/index", //开票
            component: orderPage,
            meta: {
              title: "订单列表",
            },
          },
          {
            path: "/orderPage/mackInvoice", //开票
            component: mackInvoice,
            meta: {
              title: "开票详情",
            },
          },
          {
            path: "/orderPage/detailPage/", //订单详情页
            component: detailPage,
            meta: {
              title: "订单详情",
            },
          },
        ],
      },
      {
        path: "/invoiceList",
        redirect: "/invoiceList/index",
        meta: {
          title: "发票开具列表",
        },
        children: [
          {
            path: "/invoiceList/index",
            component: invoiceList, //发票开具列表
            meta: {
              title: "发票列表",
            },
          },
          {
            path: "/invoiceList/addInvoice",
            component: addInvoice, //新增编辑发票开具信息
            meta: {
              title: "新增编辑发票开具信息",
            },
          },
          {
            path: "/invoiceList/invoiceInfo/",
            component: invoiceInfo, //发票详情
            meta: {
              title: "发票详情",
            },
          },
        ],
      },

      {
        path: "/userPage", //用户管理
        redirect: "/userPage/index",
        meta: {
          title: "用户管理",
        },
        children: [
          {
            path: "/userPage/index",
            component: userPage,
            meta: {
              title: "用户列表",
            },
          },
          {
            path: "/userPage/changeUser",
            component: changeUser, //编辑用户
            meta: {
              title: "编辑用户",
            },
          },
          {
            path: "/userPage/userDetails",
            name: "userDetails",
            component: userDetails, //用户详情页
            meta: {
              title: "用户详情页",
            },
          },
        ],
      },
      {
        path: "/productPage", //产品管理
        redirect: "/productPage/index",
        meta: {
          title: "产品管理",
        },
        children: [
          {
            path: "/productPage/index",
            component: productPage,
            meta: {
              title: "产品列表",
            },
          },
          {
            path: "/productPage/addProduct", //新增产品
            component: addProduct,
            meta: {
              title: "新增产品",
            },
          },
          {
            path: "/productPage/editProduct", //新增产品
            component: editProduct,
            meta: {
              title: "编辑产品",
            },
          },
          {
            path: "/productPage/productInfo", //产品详情
            component: productInfo,
            meta: {
              title: "产品详情",
            },
          },
        ],
      },

      {
        path: "/adminPage", //管理员管理
        redirect: "/adminPage/index",
        meta: {
          title: "管理员管理",
        },
        children: [
          {
            path: "/adminPage/index",
            component: adminPage,
            meta: {
              title: "管理员列表",
            },
          },
          {
            path: "/adminPage/addAdmin", //添加管理员
            component: addAdmin,
            meta: {
              title: "添加管理员",
            },
          },

          {
            path: "/adminPage/adminInfo", //管理员详情
            component: adminInfo,
            meta: {
              title: "管理员详情",
            },
          },
          {
            path: "/adminPage/redactAdmin", //编辑管理员
            component: redactAdmin,
            meta: {
              title: "编辑管理员",
            },
          },
        ],
      },

      // {
      //   path: "/announceMent", //公告管理
      //   redirect: "/announceMent/index",
      //   meta: {
      //     title: "公告管理",
      //   },
      //   children: [
      //     {
      //       path: "/announceMent/index",
      //       component: announceMent,
      //       meta: {
      //         title: "公告列表",
      //       },
      //     },
      //     {
      //       path: "/announceMent/putMent", //新增公告
      //       component: putMent,
      //       meta: {
      //         title: "新增公告",
      //       },
      //     },
      //   ],
      // },
    ],
  },
];

export const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
