import request from "@/utils/request";

//登录
export function userLogin(params) {
  return request({
    url: "/adminUser/login",
    method: "post",
    data: JSON.parse(JSON.stringify(params)),
    Headers: {
      Authorization: "",
      "Content-Type": "application/json",
    },
  });
}
// 获取订单数据
export function getCaptcha() {
  return request({
    url: "/captcha",
    method: "get",
  });
}
