<template>
  <!-- 发票开具列表 -->
  <div class="invoice-page">
    <div class="header-title"><span>发票开具列表</span></div>
    <div class="find-ipt"></div>
    <el-table
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"

      style="width: 90%; margin: auto"
      border
      height="430"
      v-loading="loading"
      :header-cell-style="{
          background: '#f2f2f2',
          color: '#101010',
          'text-align': 'center',
          height: '10px',
        }"
       
    >
      <el-table-column
        label="用户名"
       width="200"
        prop="realName"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="invoiceHeading"
        label="公司名称"
       width="200"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="productName"
        label="产品名称"
       width="200"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="price"
        label="金额 (元)"
       width="200"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="authorizationDuration"
        label="注册时长 (年)"
       width="200"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="createdTime"
        label="开具时间"
       width="200"
        align="center"
      >
      </el-table-column>

      <el-table-column label="操作" width="222" align="center"  fixed="right">
        <template #default="{ row }">
          <el-button
            size="small"
            type="primary"
            plain
            @click="invoiceInfoPage(row.id)"
          >
            详情
          </el-button>
          <el-popconfirm
            title="确定删除吗?"
            @Confirm="invoiceDel(row.id)"
            confirm-button-text="确定"
            cancel-button-text="取消"
          >
            <template #reference>
              <el-button size="small" plain type="warning">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-row class="invoice-btn">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </el-row>
  </div>
</template>

<script>
import { getInvoiceData, delInvoiceData } from "@/api/invoice.js";
export default {
  data() {
    return {
      loading: false,
      multipleSelection: [],
      tableData: [],
      pageSize: 10,
      page: 1,
      total: 0,
    };
  },
  created() {
    this.getInvoiceData();
  },
  methods: {
    // 页码改变
    handleCurrentChange(e) {
      this.page = e;
      this.getInvoiceData();
    },
    // 数量改变
    handleSizeChange(e) {
      this.pageSize = e;
      this.getInvoiceData();
    },
    async getInvoiceData() {
      this.loading = true;
      const { pageSize, page } = this;
      const {
        data: { rows, records },
      } = await getInvoiceData({ pageSize, page });
      this.tableData = rows;
      this.total = records;
      this.loading = false;
    },
    gotoAdd() {
      this.$router.push("/invoiceList/addInvoice");
    },
    //详情页
    invoiceInfoPage(id) {
      this.$router.push({
        path: "/invoiceList/invoiceInfo",
        query: { id: id },
      });
    },
    //删除数据
    invoiceDel(invoicesId) {
      delInvoiceData(invoicesId)
        .then((res) => {
          if (res.status == 200) {
            this.$message.success("删除成功");
            //删除成功 回调列表接口
            this.getInvoiceData();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {
          this.$message.error("删除失败！");
        });
    },
  },
};
</script>

<style lang="less" scoped>
.invoice-page {
  width: 92%;
  height: 85%;
  margin: auto;
  min-height: 600px;
  border: 1px solid #fff;
  border-radius: 13px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  .header-title {
    width: 100%;
    height: 0.75rem;
    line-height: 0.75rem;
    font-size: 0.225rem;
    background-color: #f9f9f9;
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
    span {
      margin-left: 0.25rem;
    }
  }
  .find-ipt {
    display: flex;
    margin: 0.25rem;
    .newAdd {
      margin-right: 0.5rem;
    }
  }
  .invoice-btn {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
</style>
