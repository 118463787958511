export const menusConfig = [
  { name: "home", menuItem: "系统首页" },
  { name: "orderPage", menuItem: "订单管理", value: "2" },
  { name: "invoiceList", menuItem: "发票开具列表", value: "3" },
  { name: "userPage", menuItem: "用户管理", value: "4" },
  { name: "productPage", menuItem: "产品管理", value: "5" },
  { name: "adminPage", menuItem: "管理员管理", value: "6" },
 // { name: "basicsPage", menuItem: "系统管理", value: "8" },
 // { name: "announceMent", menuItem: "公告管理", value: "7" },
];
export const superCode = "1";
