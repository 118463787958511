<template>
  <!-- 用户详情 -->
  <div class="user-details">
    <div class="personage">
      <div class="left-info">
        <div class="user-name">
          <p>{{ record.realName || "无名" }}</p>
          <div class="person"><span>个人用户</span></div>
        </div>
        <div class="user-icon">
          <p>用户状态</p>
          <div class="normal">
            <span></span><i> {{ record.status === "1" ? "正常" : "停用" }}</i>
          </div>
        </div>
        <div class="user-money">
          <p>消费金额</p>
          <span>¥</span><span>{{ record.consumptionAmount }}</span>
        </div>
        <div class="login-number">
          <p>登录次数</p>
          <span>100</span>
        </div>
      </div>
      <div class="right-btn mr-2">
        <el-button type="primary" class="btn">修改资料</el-button>
        <el-button class="btn">重置密码</el-button>
      </div>
    </div>

    <div class="personData px-2">
      <!-- <div class="user-title"><span>用户详情</span><span>订单记录</span></div> -->
      <el-tabs model-value="用户详情">
        <el-tab-pane label="用户详情" name="用户详情">
          <div class="subscriber-data">
            <div class="title">
              <div class="box"></div>
              <span>用户资料</span>
            </div>
            <el-descriptions
              direction="vertical"
              class="my-1"
              :column="4"
              border
            >
              <el-descriptions-item label="id编号">
                {{ record.id || "无" }}
              </el-descriptions-item>
              <el-descriptions-item label="用户状态">
                {{ record.status === "1" ? "正常" : "停用" }}
              </el-descriptions-item>
              <el-descriptions-item label="手机号码">
                {{ record.mobile || "无" }}
              </el-descriptions-item>
              <el-descriptions-item label="用户类型">
                企事业单位
              </el-descriptions-item>

              <el-descriptions-item label="单位名称">无</el-descriptions-item>
              <el-descriptions-item label="注册方式">
                {{
                  record.registrationMethod === "1" ? "后台注册" : "自主注册"
                }}
              </el-descriptions-item>
              <el-descriptions-item label="注册时间">
                {{ record.createdTime || "" }}
              </el-descriptions-item>
              <el-descriptions-item label="最后登录">无</el-descriptions-item>
            </el-descriptions>
            <div class="title">
              <div class="box"></div>
              <span>统计信息</span>
            </div>
            <el-descriptions
              class="my-1"
              direction="vertical"
              :column="4"
              border
            >
              <el-descriptions-item label="消费金额">
                ¥{{ record.consumptionAmount || 0.0 }}
              </el-descriptions-item>
              <el-descriptions-item label="订单记录">
                {{ record.orderQuantity || 0 }} <i>查看</i>
              </el-descriptions-item>
              <el-descriptions-item label="登录次数"> 无 </el-descriptions-item>
            </el-descriptions>
            <div class="title">
              <div class="box"></div>
              <span>收货地址</span>
            </div>
            <el-descriptions
              class="my-1"
              direction="vertical"
              :column="4"
              border
            >
              <el-descriptions-item label="姓名">
                {{ record.name || "无" }}
              </el-descriptions-item>
              <el-descriptions-item label="手机号码">
                {{ record.mobile || "无" }}
              </el-descriptions-item>
              <el-descriptions-item label="邮政编码"> 无 </el-descriptions-item>
              <el-descriptions-item label="默认地址"> 无 </el-descriptions-item>
            </el-descriptions>
          </div>
        </el-tab-pane>
        <el-tab-pane label="订单记录" name="订单记录">
          <el-table :data="tableData" height="453" border v-loading="loading"  :header-cell-style="{
                background: '#f2f2f2',
                color: '#101010',
                'text-align': 'center',
                height: '10px',
              }">
            <el-table-column
              label="序号"
              width="120"
              type="index"
              align="center"
             
            >
            </el-table-column>

            <el-table-column
              prop="ordersId"
              label="订单编号"
              width="200"
              :show-overflow-tooltip="true"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="orderStatus"
              label="订单状态"
              width="180"
              align="center"
            >
              <template #default="{ row }">
                <div
                  :style="{ color: row.orderStatus == '2' ? '#29D100' : 'red' }"
                >
                  {{ row.orderStatus == 2 ? "• 激活" : "• 已过期" }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="productName"
              label="订单产品"
              width="200"
              :show-overflow-tooltip="true"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="authorizationDuration"
              label="订购时长(年)"
              width="180"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="payTime"
              label="订购时间"
              width="200"
              :show-overflow-tooltip="true"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="invoicesStatus"
              label="发票状态"
              width="120"
              align="center"
            >
              <template #default="{ row }">
                <div
                  :style="{
                    color: row.invoicesStatus == '2' ? '#29D100' : 'red',
                  }"
                >
                  {{ row.invoicesStatus == 2 ? "• 已开" : "• 未开" }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              width="252"
              align="center"
              fixed="right"
            >
              <template #default="{ row }">
                <el-button size="small" plain type="primary">详情</el-button>
                <el-button size="small" plain type="primary">备注</el-button>
                <el-popconfirm
                  title="确定删除吗?"
                  @Confirm="confirmDel(row.ordersId)"
                  confirm-button-text="确定"
                  cancel-button-text="取消"
                >
                  <template #reference>
                    <el-button size="small" plain type="warning"
                      >删除</el-button
                    >
                  </template>
                </el-popconfirm>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            class="nation mt-2"
            background
            layout="total,  sizes,  prev,  pager,  next"
            :total="total"
            :page-size="pageSize"
            :current-page="page"
            :page-sizes="[10, 20, 30, 50]"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { getOrderData } from "@/api/order";
const route = useRoute();
const record = ref(route.query);
const tableData = ref([]);
const pageSize = ref(10);
const page = ref(1);
const total = ref(0);
const loading = ref(false);

async function fetchData() {
  loading.value = true;
  const { data } = await getOrderData({
    page: page.value,
    pageSize: pageSize.value,
    userId: record.value.id,
  });
  loading.value = false;
  // tableData.splice(0, tableData.length, ...data.rows);
  tableData.value = data.rows;
  total.value = data.records;
}

function handleCurrentChange(pageNumber) {
  page.value = pageNumber;
  fetchData();
}

function handleSizeChange(pageSize) {
  pageSize.value = pageSize;
  fetchData();
}

onMounted(() => fetchData());
</script>

<style lang="less" scoped>
.user-details {
  width: 92%;
  height: 90%;
  margin: auto;
  border-radius: 13px;
  background-color: #fff;
  display: flex;
  flex-direction: column;

  .personage {
    width: 98%;
    height: 10%;
    min-height: 50px;
    background-color: #fff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left-info {
      width: 70%;
      height: 50%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      font-size: 0.2rem;
      .user-name {
        p {
          margin-bottom: 0.1rem;
        }
        .person {
          width: 80px;
          background-color: palegoldenrod;
          border-radius: 20px;
          text-align: center;
          span {
            font-size: 0.1875rem;
          }
        }
      }
      .user-icon {
        p {
          color: #999;
          margin-bottom: 0.1rem;
        }
        .normal {
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            display: block;
            width: 0.125rem;
            height: 0.125rem;
            background-color: #19be6b;
            margin-right: 0.125rem;
            border-radius: 50%;
          }
          i {
            color: #666;
            font-size: 14px;
          }
        }
      }
      .user-money {
        p {
          color: #999;
          margin-bottom: 0.1rem;
        }
      }
      .login-number {
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
          color: #999;
          margin-bottom: 0.1rem;
        }
      }
    }
    .right-btn {
      width: 15%;
      display: flex;
      justify-content: center;
      .btn {
        width: 1.25rem;
        font-size: 0.175rem;
      }
    }
  }

  .personData {
    width: 98%;
    height: 80%;
    margin: auto;
    border: 1px solid #fff;
    border-radius: 13px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    margin: auto;
    .user-title {
      width: 100%;
      height: 0.75rem;
      line-height: 0.75rem;
      font-size: 0.225rem;
      background-color: #f9f9f9;
      color: #666;
      border-top-left-radius: 13px;
      border-top-right-radius: 13px;
      span {
        margin-left: 0.5rem;
        font-size: 0.2rem;
        color: #666;
      }
    }
    .subscriber-data {
      height: 35%;

      .title {
        height: 25%;
        display: flex;
        align-items: center;
        font-size: 0.175rem;
        .box {
          width: 0.0625rem;
          height: 0.25rem;
          background-color: #409eff;
          // margin-left: 0.25rem;
          margin-right: 0.125rem;
        }
      }
      .subscriber-table {
        width: 97%;
        height: 74%;
        margin: auto;
        border: 1px solid #dbdbdb;
        .one-box {
          width: 100%;
          height: 25%;
          border-bottom: 1px solid #dbdbdb;
          display: flex;
          justify-content: space-around;
          align-items: center;
          span {
            width: 100%;
            height: 100%;
            border-right: 1px solid #dbdbdb;
            // text-align: center;
            display: flex;
            align-items: center;
            // justify-content: center;
            font-size: 0.175rem;
            margin-left: 0.0625rem;
          }
        }
      }
      .statistics-table {
        width: 100%;
        height: 40%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .one-box {
          width: 97%;
          height: 50%;
          background-color: #f5f5f5;
          border: 1px solid #dbdbdb;
          display: flex;
          span {
            width: 100%;
            height: 100%;
            border-right: 1px solid #dbdbdb;
            display: flex;
            align-items: center;
            font-size: 0.175rem;
            margin-left: 0.0625rem;
          }
        }
        .one-box:nth-child(2) {
          border-top: none;
          background-color: #fff;
          i {
            color: #409eff;
            margin-left: 0.125rem;
            cursor: pointer;
          }
        }
      }
      .shipping-address {
        width: 97%;
        height: 40%;
        margin: auto;
        // border: 1px solid #dbdbdb;
        .ship-box {
          width: 100%;
          height: 50%;
          background-color: #f5f5f5;
          border: 1px solid #dbdbdb;
          display: flex;
          span {
            width: 100%;
            height: 100%;
            border-right: 1px solid #dbdbdb;
            display: flex;
            align-items: center;
            font-size: 0.175rem;
            margin-left: 0.0625rem;
          }
          .name {
            width: 50%;
          }
          .phone {
            width: 80%;
          }
          .code {
            width: 50%;
          }
          .dress {
            border-right: none;
          }
        }
      }
    }
  }
  .nation {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
</style>
