// 发票开具列表

import request from "@/utils/request";

// 获取发票开具列表数据
export function getInvoiceData(params) {
  return request({
    url: "/admin/invoicesList",
    method: "get",
    params,
  });
}

// 获取列表详情
export function getInvoiceInfoData(invoicesId) {
  return request({
    url: "/admin/invoicesInfo?invoicesId=" + invoicesId,
    method: "get",
  });
}

/**删除列表数据 */
export function delInvoiceData(invoicesId) {
  return request({
    url: "/admin/removeInvoices?invoicesId=" + invoicesId,
    method: "post",
  });
}

// 获取开票详情
export function getMackInvoiceData(ordersId) {
  return request({
    url: "/admin/createInvoices" + ordersId,
    method: "post",
  });
}

// 获取开票
export const createInvoices = (data) =>
  request({
    url: "/admin/createInvoices",
    method: "post",
    data,
  });
