import { transform, isEqual, difference, isObject, isArray } from "lodash-es";
// 获取token
export const getToken = () => sessionStorage.getItem("TOKEN");
// 比对两个对象的差异
export const deepDiffBetweenObjects = (object, base) => {
  const changes = (object, base) => {
    return transform(object, (result, value, key) => {
      if (!isEqual(value, base[key])) {
        if (isArray(value)) {
          result[key] = difference(value, base[key]);
        } else if (isObject(value) && isObject(base[key])) {
          result[key] = changes(value, base[key]);
        } else {
          result[key] = value;
        }
      }
    });
  };
  return changes(object, base);
};
