<!-- 产品管理详情 -->
<template>
  <div class="product-info">
    <div class="preview-data">
      <el-row class="row-box">
        <el-col :span="6" class="user-name">
          <!-- <div class="box"></div> -->
          <div class="company-name">
            <h1>{{ record.name || "" }}</h1>
            <p>{{ record.softwareDescription || "" }}</p>
          </div>
        </el-col>
        <el-col :span="3" class="stats"
          ><p>当前状态</p>
          <div class="up">
            <div class="box"></div>
            <span>{{ record.onOffStatus == 1 ? "已上线" : "未上线" }}</span>
          </div></el-col
        >
        <el-col :span="4"
          ><p class="text">销售数量</p>
          <p class="number">{{ record.userCount || 0 }}</p></el-col
        >
        <el-col :span="4"
          ><p class="text">发布时间</p>
          <p class="number">{{ record.createdTime || "" }}</p></el-col
        >
        <el-col :span="2"></el-col>
        <el-col :span="3">
          <el-button type="primary" class="btn" @click="handelEdit"
            >编辑产品</el-button
          >
          <el-button class="btn">删除项目</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="pro-msg">
      <el-tabs v-model="activeTab" @tab-click="handleClick">
        <el-tab-pane label="产品信息" name="product">
          <div class="label-box" style="margin: 30px 0">
            <div class="box"></div>
            <span style="font-size: 18px">基本信息</span>
          </div>
          <el-descriptions border>
            <el-descriptions-item
              label="产品名称:"
              label-align="right"
              width="1.875rem"
            >
              {{ record.name || "" }}
            </el-descriptions-item>
            <el-descriptions-item
              label="最新版本:"
              label-align="right"
              width="1.875rem"
            >
              {{ record.latestVersion || "" }}
            </el-descriptions-item>
            <el-descriptions-item
              label="上架状态:"
              label-align="right"
              width="1.875rem"
            >
              {{ record.onOffStatus == 1 ? "已上架" : "未上架" }}
            </el-descriptions-item>
          </el-descriptions>
          <el-descriptions border>
            <el-descriptions-item
              label="用户群体:"
              label-align="right"
              width="1.875rem"
            >
              {{
                record.userGroup == 1
                  ? "个人用户"
                  : record.userGroup == 2
                  ? "企事业单位"
                  : "政务部门"
              }}
            </el-descriptions-item>
            <el-descriptions-item
              label="用户数量:"
              label-align="right"
              width="1.875rem"
            >
              {{ record.userCount || 0 }}
            </el-descriptions-item>
            <el-descriptions-item
              label="销售金额:"
              label-align="right"
              width="1.875rem"
            >
              {{ record.salesAmount || 0.0 }}
            </el-descriptions-item>
          </el-descriptions>
          <el-descriptions border>
            <el-descriptions-item
              label="软件介绍:"
              label-align="right"
              width="2.975rem"
            >
              {{ record.softwareDescription || "" }}
            </el-descriptions-item>
          </el-descriptions>
        </el-tab-pane>
        <el-tab-pane label="版本发布" name="release">
          <h1 class="title">版本发布</h1>
          <div class="form">
            <el-form
              ref="releaseForm"
              :model="releaseForm"
              label-width="80px"
              :rules="rules"
            >
              <el-form-item label="产品名称:" label-width="100px">
                <span>数智晟泰林业信息采集系统</span>
              </el-form-item>
              <el-form-item
                label="版本号:"
                label-width="100px"
                prop="versionNumber"
              >
                <el-input v-model="releaseForm.versionNumber"></el-input>
              </el-form-item>
              <el-form-item
                label="升级语音提示:"
                label-width="100px"
                prop="prompt"
              >
                <el-input v-model="releaseForm.prompt"></el-input>
              </el-form-item>
              <el-form-item label="下载地址:" label-width="100px" prop="link">
                <div class="row">
                  <el-input v-model="releaseForm.link">
                    <template #prepend>http://</template>
                  </el-input>
                  <el-upload
                    :action="uploadAction"
                    :headers="uploadHeaders()"
                    :on-success="uploadSuccess"
                    :file-list="fileList"
                  >
                    <el-button type="primary" style="margin: 10px 0 0 5px"
                      ><el-icon class="el-icon--right"><Upload /></el-icon>
                      上传文件
                    </el-button>
                  </el-upload>
                </div>
              </el-form-item>
              <el-form-item
                label="平台最低版本:"
                label-width="100px"
                prop="supportedVersionId"
              >
                <el-input-number
                  v-model="releaseForm.supportedVersionId"
                  placeholder="请输入最低版本"
                ></el-input-number>
              </el-form-item>
              <el-form-item
                label="升级类型:"
                label-width="100px"
                prop="updateType"
              >
                <el-select
                  v-model="releaseForm.updateType"
                  placeholder="请选择类型"
                >
                  <el-option label="APP新上架" :value="1"></el-option>
                  <el-option label="弱提示升级" :value="2"></el-option>
                  <el-option label="强提示升级" :value="3"></el-option>
                  <el-option label="强制更新" :value="4"></el-option>
                </el-select>
              </el-form-item>

              <el-form-item
                label="版本介绍:"
                label-width="100px"
                prop="softwareDescription"
              >
                <el-input
                  type="textarea"
                  v-model="releaseForm.softwareDescription"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="releaseVersion"
                  >立即创建</el-button
                >
                <el-button @click="resetReleaseForm">重置</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-tab-pane>
        <el-tab-pane label="发布记录" name="record">
          <div class="search">
            <el-form
              ref="searchForm"
              :inline="true"
              :model="searchForm"
              class="demo-form-inline"
            >
              <el-form-item>
                <el-button
                  type="primary"
                  style="width: 1.25rem; margin-left: 0.9375rem"
                  >发布APP</el-button
                >
              </el-form-item>
              <el-form-item prop="updateType">
                <el-select
                  v-model="searchForm.updateType"
                  placeholder="升级类型"
                >
                  <el-option label="APP新上架" value="1"></el-option>
                  <el-option label="弱提示升级" value="2"></el-option>
                  <el-option label="强提示升级" value="3"></el-option>
                  <el-option label="强制更新" value="4"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="versionNumber">
                <el-input
                  placeholder="版本"
                  v-model="searchForm.versionNumber"
                ></el-input>
              </el-form-item>
              <el-form-item prop="adminUser">
                <el-input
                  placeholder="发布人"
                  v-model="searchForm.adminUser"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="onRecord()">查询</el-button>
                <el-button type="primary" @click="resetRecordForm('searchForm')"
                  >重置</el-button
                >
              </el-form-item>
            </el-form>

            <el-table
              :data="releaseData"
              border
              style="width: 90%; margin: auto"
              :header-cell-style="{
                background: '#f2f2f2',
                color: '#101010',
                'text-align': 'center',
                height: '10px',
              }"
            >
              <el-table-column
                type="index"
                label="序号"
                width="120"
                align="center"
              />
              <el-table-column
                prop="adminName"
                label="发布人员"
                width="200"
                align="center"
              />
              <el-table-column
                prop="updateType"
                label="升级类型"
                width="200"
                align="center"
              >
                <template #default="{ row }">
                  {{
                    row?.updateType === 1
                      ? "APP新上架"
                      : row?.updateType === 2
                      ? "弱提示升级"
                      : row?.updateType === 3
                      ? "强提示升级"
                      : row?.updateType === 4
                      ? "强制更新"
                      : ""
                  }}
                </template>
              </el-table-column>
              <el-table-column
                prop="versionNumber"
                label="发布版本"
                width="200"
                align="center"
              />
              <el-table-column
                prop="onOffStatus"
                label="上架状态"
                width="200"
                align="center"
              >
                <template #default="{ row }">
                  {{ row.onOffStatus === 1 ? "已上架" : "已下架" }}
                </template>
              </el-table-column>
              <el-table-column
                prop="fileSize"
                label="文件大小"
                width="200"
                align="center"
              />
              <el-table-column
                fixed="right"
                prop="createdTime"
                label="发布时间"
                width="260"
                align="center"
              />
            </el-table>
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="searchForm.page"
              :page-size="searchForm.pageSize"
              background
              layout="total, sizes, prev, pager, next"
              :total="total"
              class="pageText"
            />
          </div>
        </el-tab-pane>
        <el-tab-pane label="订单记录" name="order">
          <el-form
            :inline="true"
            :model="orderForm"
            ref="orderForm"
            class="demo-form-inline"
          >
            <el-form-item></el-form-item>
            <el-form-item style="margin-left: 0.25rem" prop="companyName">
              <el-input
                v-model="orderForm.companyName"
                placeholder="用户名称"
              />
            </el-form-item>
            <el-form-item prop="authorizationDuration">
              <el-input
                v-model="orderForm.authorizationDuration"
                placeholder="订单时间"
              />
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="orderSubmit()">查询</el-button>
              <el-button type="primary" @click="resetOrderForm('orderForm')"
                >重置</el-button
              >
            </el-form-item>
          </el-form>
          <el-table
            :data="orderData"
            border
            style="width: 93%; margin: auto"
            :header-cell-style="{
              background: '#f2f2f2',
              color: '#101010',
              'text-align': 'center',
              height: '10px',
            }"
          >
            <el-table-column type="index" label="序号" width="200" />
            <el-table-column prop="companyName" label="用户名称" width="250" />
            <el-table-column prop="payTime" label="订单时间" width="250" />
            <el-table-column
              prop="expirationTime"
              label="到期时间"
              width="250"
            />
            <el-table-column
              prop="authorizationDuration"
              label="购买时长"
              width="226"
            />
            <el-table-column
              prop="totalAmount"
              label="支付金额(元)"
              width="250"
              fixed="right"
            />
          </el-table>
          <el-pagination
            @size-change="orderSizeChange"
            @current-change="orderCurrentChange"
            :current-page="pageParams.page"
            :page-size="pageParams.pageSize"
            background
            layout="total, sizes, prev, pager, next"
            :total="total"
            class="pageText"
          />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import {
  getProductInfoData,
  versionRelease,
  releaseHistory,
  productOrders,
} from "@/api/product";
import { uploadAction, uploadHeaders } from "@/config/upload";
export default {
  data() {
    return {
      activeTab: "product",
      tableData: [],
      //版本发布 form
      releaseForm: {
        icon: "",
        productId: this.$route.query.id,
        versionNumber: "",
        prompt: "",
        link: "",
        supportedVersionId: 1.0,
        updateType: "",
        fileSize: "",
        softwareDescription: "",
      },
      // 上传配置
      uploadAction,
      uploadHeaders,
      // 文件列表
      fileList: [],
      // 规则验证
      rules: [
        {
          versionNumber: [
            { required: true, message: "请输入用户名", trigger: "blur" },
          ],
        },
      ],
      //发布记录
      searchForm: {
        adminUser: "",
        updateType: "",
        versionNumber: "",
      },
      releaseData: [],
      searchList: {},
      //订单记录
      orderForm: {
        companyName: "",
        authorizationDuration: "",
        productId: this.$route.query.id,
      },
      orderData: [],
      pageParams: {
        page: 1,
        pageSize: 10,
      },
      // 发布信息
      record: {},
      id: null,
      total: 0,
    };
  },
  created() {
    let id = this.$route.query.id;
    this.id = id;
    id && this.getProductInfoData(id);
  },
  methods: {
    // 页码改变
    handleCurrentChange(e) {
      this.searchForm.page = e;
      this.getReleaseHistory();
    },
    orderCurrentChange(e) {
      this.pageParams.page = e;
      this.getOrderHistory();
    },
    // 数量改变
    handleSizeChange(e) {
      this.searchForm.pageSize = e;
      this.getReleaseHistory();
    },
    orderSizeChange(e) {
      this.pageParams.pageSize = e;
      this.getOrderHistory();
    },
    addProduct() {
      this.$router.push("/productPage/addProduct");
    },
    async getProductInfoData() {
      const { id } = this;
      const { data } = await getProductInfoData(id);
      // 做一次赋值就行
      this.record = data;
    },
    handelEdit() {
      const { record } = this;
      // TODO 这里带上商品数据过去就行
      this.$router.push({
        path: "/productPage/editProduct",
        query: record,
      });
    },

    handleClick({ props: { name } }) {
      switch (name) {
        case "product":
          this.getProductInfoData();
          break;
        case "release":
          this.resetReleaseForm();
          break;
        case "record":
          this.getReleaseHistory();
          break;
        case "order":
          this.getOrderHistory();
          break;
      }
    },
    //版本发布
    releaseVersion() {
      // 调用API来提交数据
      versionRelease(this.releaseForm).then(() => {
        this.fileList = [];
        this.resetReleaseForm();
        this.activeTab = "release";
      });
    },
    // 重置表单
    resetReleaseForm() {
      this.$refs.releaseForm.resetFields();
    },
    //文件上传
    uploadSuccess(response, file) {
      if (response.status === 200) {
        this.fileList.push({
          name: file.name,
          url: response.data.imageUrl,
        });
        /// 文件特殊处理
        this.releaseForm.link = response.data.imageUrl.replace("http://", "");
        this.releaseForm.fileSize = response.data.size;
      }
    },
    //发布记录
    async getReleaseHistory() {
      releaseHistory(this.searchForm).then((res) => {
        if (res.status === 200) {
          this.releaseData = res.data.rows;
          this.total = res.data.records; //分页总数
        } else {
          // 如果接口返回的code不为200，显示错误信息
          this.$message.error(res.msg);
        }
      });
    },
    //发布记录查询
    onRecord() {
      this.searchForm.page = 1;
      this.getReleaseHistory();
    },
    //发布记录重置
    resetRecordForm(searchForm) {
      this.$refs[searchForm].resetFields();
      this.getReleaseHistory();
    },
    //订单记录
    async getOrderHistory() {
      const { pageParams, orderForm } = this;
      productOrders({ ...orderForm, ...pageParams }).then((res) => {
        this.orderData = res.data.rows;
        this.total = res.data.records; //分页总数
      });
    },
    //订单查询
    orderSubmit() {
      this.pageParams.page = 1;
      this.getOrderHistory();
    },
    //订单重置
    resetOrderForm(orderForm) {
      this.pageParams.page = 1;
      this.$refs[orderForm].resetFields();
      this.getOrderHistory();
    },
  },
};
</script>

<style lang="less" scoped>
.product-info {
  width: 92%;
  height: 90%;
  margin: auto;
  border-radius: 13px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  .preview-data {
    width: 98%;
    height: 10%;
    min-width: 900px;
    min-height: 50px;
    background-color: #fff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin: auto;
    .row-box {
      height: 100%;
      display: flex;
      align-items: center;
      .user-name {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        .box {
          width: 0.625rem;
          height: 0.625rem;
          background: palevioletred;
          border-radius: 50%;
        }
        .company-name {
          h1 {
            font-size: 0.225rem;
            color: #666;
          }
          p {
            font-size: 0.175rem;
            color: #999;
          }
        }
      }
      .stats {
        width: 100%;
        margin: auto;
        p {
          color: #999;
          font-size: 0.175rem;
        }
        .up {
          display: flex;
          margin-top: 0.0625rem;
          align-items: center;
          .box {
            width: 0.1875rem;
            height: 0.1875rem;
            background: #51d351;
            border-radius: 50%;
            margin-right: 0.0625rem;
          }
          span {
            color: #ccc;
          }
        }
      }
      .text {
        font-size: 0.175rem;
        color: #999;
      }
      .number {
        color: #ccc;
      }
      .btn {
        width: 1rem;
        font-size: 0.1625rem;
      }
    }
  }

  .pro-msg {
    width: 98%;
    height: 80%;
    min-width: 900px;
    min-height: 600px;
    margin: auto;
    border: 1px solid #fff;
    border-radius: 13px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    margin: auto;
    /deep/.el-tabs__item {
      height: 0.8125rem;
      font-size: 0.225rem !important;
      margin-left: 0.125rem;
    }
    /deep/.el-descriptions__body .el-descriptions__table {
      width: 93%;
      margin: auto;
    }

    .title {
      font-size: 0.2rem;
      font-weight: 700;
      margin-left: 0.5rem;
    }
    .form {
      width: 90%;
      display: flex;
      justify-content: center;
      margin: auto;
      .row {
        display: flex;
        align-items: center;
      }
      .upload-demo {
        margin-left: 10px;
      }
      .el-form-item {
        display: flex;
        align-items: center;
      }
    }
    .search {
      width: 100%;
    }
    .pageText {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .pro-title {
      width: 100%;
      height: 10%;
      background: #f9f9f9;
      border-top-right-radius: 13px;
      border-top-left-radius: 13px;

      .msg {
        width: 40%;
        height: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        span {
          font-size: 0.2rem;
          color: #999;
          font-weight: 700;
        }
      }
    }

    .label-box {
      width: 100%;
      height: 10%;
      display: flex;
      align-items: center;
      .box {
        width: 0.0625rem;
        height: 0.3125rem;
        background: #409eff;
        margin-left: 0.625rem;
      }
      span {
        font-size: 0.175rem;
        color: #666;
        margin-left: 0.0625rem;
      }
    }

    .table-info {
      width: 100%;
      height: 75%;
      .msg-box {
        width: 93%;
        height: 100%;
        margin: auto;
        border: 1px solid #dbdbdb;
        .one {
          width: 100%;
          height: 10%;
          display: flex;
          justify-content: space-around;
          align-items: center;
          .name,
          .new,
          .state,
          .name-msg,
          .number,
          .state-msg {
            width: 17%;
            height: 100%;
            border-right: 1px solid #dbdbdb;
            display: flex;
            align-items: center;
          }
          .name,
          .new,
          .state {
            border-bottom: 1px solid #dbdbdb;
            span {
              width: 100%;
              text-align: center;
              font-size: 0.175rem;
              color: #666;
            }
          }
          .name-msg,
          .number,
          .state-msg {
            border-bottom: 1px solid #dbdbdb;
            span {
              margin: 0 0.0625rem;
            }
          }
          .state-msg {
            border-right: none;
            .circle {
              width: 0.125rem;
              height: 0.125rem;
              background: #51d351;
              border-radius: 50%;
              margin: 0 0.0625rem;
            }
          }
        }
        .two {
          width: 100%;
          height: 10%;
          display: flex;
          justify-content: space-around;
          align-items: center;
          .user,
          .user-msg,
          .user-count,
          .count,
          .money,
          .money-msg {
            width: 17%;
            height: 100%;
            border-right: 1px solid #dbdbdb;
            display: flex;
            align-items: center;
          }
          .user,
          .user-count,
          .money {
            border-bottom: 1px solid #dbdbdb;
            span {
              width: 100%;
              text-align: center;
              font-size: 0.175rem;
              color: #666;
            }
          }
          .user-msg,
          .count,
          .money-msg {
            border-bottom: 1px solid #dbdbdb;
            span {
              margin: 0 0.0625rem;
            }
          }
          .money-msg {
            border-right: none;
          }
        }
        .text {
          width: 100%;
          display: flex;
          height: 80%;
          .title {
            width: 16.5%;
            height: 100%;
            border-right: 1px solid #dbdbdb;
            text-align: center;
            span {
              display: block;
              height: 10%;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #666;
              border-bottom: 1px solid #dbdbdb;
            }
          }
          .text-info {
            width: 70%;
            height: 90%;
            margin: auto;
          }
        }
      }
    }

    .boxs {
      width: 100%;
      height: 100%;
      background: pink;
    }
  }
}
</style>
