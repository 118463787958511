import axios from "axios";
import { Promise } from "core-js";
import { ElMessage } from "element-plus";
import { getToken } from "@/utils";
import store from "@/store";
import { baseURL } from "@/config/env";

const qs = require("qs");
const logoutStatus = [5555, 5556, 522, 501];
axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
const service = axios.create({
  baseURL:baseURL,
  withCredentials: true,
  timeout: 5000,
  // headers: {
  //   "Access-Control-Allow-Origin": "*",
  //   "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
  //   "Access-Control-Allow-Headers": "Content-Type, Authorization",
  // },
});

service.interceptors.request.use(
  (config) => {
    if (getToken()) {
      config.headers["Authorization"] = getToken(); //	可以是授权凭证用的参数值
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
service.interceptors.response.use(
  (response) => {
    const status = response.data.status;
    if (status === 200) return response.data;
    if (logoutStatus.includes(status)) {
      store.dispatch("doLogout");
      ElMessage.error(response.data.msg);
      return Promise.reject(response.data);
    }
    ElMessage.error(response.data.msg);
    return Promise.reject(response.data);
  },
  (error) => {
    console.log(error);
    ElMessage.error(error);
    //请求没进入后台
    return Promise.reject(error);
  }
);
// eslint-disable-next-line
export function get(url, param = {}) {
  // let urlStr = "";
  // for (let i in param) {
  //   if (param[i] !== "") {
  //     urlStr += "/${param[i]}";
  //   }
  // }
  return new Promise((resolve, reject) => {
    service({
      method: "get",
      url: url,
      // const queryStr = qs.stringify(form);
    })
      .then((response) => {
        // 这里是进入后台返回的结果
        _handleResponse(response, resolve, reject);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function post(url, data = {}) {
  return new Promise((resolve, reject) => {
    service({
      method: "post",
      url: url,
      data: data,
    })
      .then((response) => {
        // 这里是进入后台返回的结果
        _handleResponse(response, resolve, reject);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getFormData(url, data = {}) {
  // eslint-disable-next-line
  debugger;
  const form = {};
  Object.keys(data).forEach((tmpkey) => {
    const tmpV = data[tmpkey];
    if (tmpV != null && (tmpV + "").length > 0) {
      form[tmpkey] = data[tmpkey];
    }
  });

  const queryStr = qs.stringify(form);

  return new Promise((resolve, reject) => {
    service({
      method: "get",
      url: url + "?" + queryStr,
    })
      .then((response) => {
        // 这里是进入后台返回的结果
        _handleResponse(response, resolve, reject);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function postFormData(url, data = {}) {
  const form = new FormData();
  Object.keys(data).forEach((tmpkey) => {
    const tmpV = data[tmpkey];
    if (tmpV != null && (tmpV + "").length > 0) {
      form.append(tmpkey, data[tmpkey]);
    }
  });
  return new Promise((resolve, reject) => {
    service({
      method: "post",
      url: url,
      data: form,
    })
      .then((response) => {
        // 这里是进入后台返回的结果
        _handleResponse(response, resolve, reject);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
function _handleResponse(response, resolve, reject) {
  if (response.state !== 200) {
    reject(response);
    //这里是请求失败的处理
    // response.status !==200根据后台返回判断
    // alert('失败')
  } else {
    resolve(response); //也可以直接返回
  }
}

/**
 * 参数处理
 * @param {*} params  参数
 */
// eslint-disable-next-line
function tansParams(params) {
  let result = "";
  for (const propName of Object.keys(params)) {
    const value = params[propName];
    var part = encodeURIComponent(propName) + "=";
    if (value !== null && value !== "" && typeof value !== "undefined") {
      if (typeof value === "object") {
        for (const key of Object.keys(value)) {
          if (
            value[key] !== null &&
            value[key] !== "" &&
            typeof value[key] !== "undefined"
          ) {
            let params = propName + "[" + key + "]";
            var subPart = encodeURIComponent(params) + "=";
            result += subPart + encodeURIComponent(value[key]) + "&";
          }
        }
      } else {
        result += part + encodeURIComponent(value) + "&";
      }
    }
  }
  return result;
}
export default service;
